import React, { useState, useEffect } from "react";
import { Card } from "../Components/Cards";
import * as config from "../config";
import { HeroHeader, Loading } from "../Components/Elements";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import { Link, NavLink } from "react-router-dom";
const PressReleases = () => {
  const [rows, setRows] = useState([]);
  const [loadingAPI, setLoadingAPI] = useState(false);
  useEffect(() => {
    document.title = "Press Releases - Aurangabad Education Expo 2023";
    if (typeof window.loadMainJS === "function") {
      window.loadMainJS();
    }
    // window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoadingAPI(true);
    fetch(`${config.API_URL}PRList`, {
      method: "GET",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => response.text())
      .then((data) => {
        var rows = JSON.parse(data).data;
        setRows(rows);
        setLoadingAPI(false);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  }, []);


  function shortdesc(txt){
	const tempDiv = document.createElement('div');
    tempDiv.innerHTML = txt;

	const words = tempDiv.textContent.trim().split(' '); // Split by spaces to get words
   return words.slice(0, 30).join(' ');

  }
  // return (<div></div>);
  return (
    <div>
      {/* <Navigation /> */}
      {/* Hero Start */}
      <HeroHeader title="PressReleases" />
      {/* Hero End */}

      {/* Event List Page Start */}
      {loadingAPI == true ? (
        <div style={{ padding: 100 }}>
          <Loading msg={`Please wait while we loading Press Releases`} />
        </div>
      ) : (
        <div className="event-list-page-wrap" style={{marginBottom: "100px"}}>
          <div
            className="container"
            style={{ paddingTop: 20, paddingBottom: 20 }}
          >
            {/* Event List Content Start */}
            <div className="event-list-content-wrap">
              <div className="tab-content">
                <div className="tab-pane  show active" id="grid">
                  <div className="row">
                    {rows &&
                      rows.map((item, index) => (
                        <>
                          <div className="col-12">
                            {/* Event List Item Start */}
                            <div className="event-list-item row">
                              <div className="event-img col-3">
                                <NavLink
                                  to={`/PressRelease/` + item.pr_slug}
                                  replace
                                >
                                  <img
                                    src={item.pr_image}
                                    style={{ width: "100%" }}
                                    alt={item.pr_title}
                                  />
                                </NavLink>
                              </div>
                              <div className="event-list-content col-9" style={{marginTop: "5px"}}>

                                <h3 className="title">
                                  <NavLink
                                    to={`/PressRelease/` + item.pr_slug}
                                    replace
                                  >
                                    {item.pr_title}{" "}
                                  </NavLink>
                                </h3>
                                <div className="meta-data">
                                  <span>
                                    <i className="far fa-clock" />{" "}
                                    {item.created_date}
                                  </span>
                                  <span>
                                    <i className="fas fa-map-marker-alt" />{" "}
                                    {`Aurangabad`}
                                  </span>
                                </div>
                                <div className="event-desc">
                                  <p>{shortdesc(item.pr_desc)}</p>
                                </div>
                                {/* <a className="ticket-link" href={`#`}>Know More</a> */}
                                <NavLink
                                  to={`/PressRelease/` + item.pr_slug}
                                  replace
                                >
                                  Know More
                                </NavLink>
                              </div>
                            </div>
                            {/* Event List Item End */}
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {/* Event List Content End */}
            {/* <div className="event-next-prev-btn text-center">
                    <a className="event-btn" href="event-list.html"><i className="flaticon-back" /> Previous</a>
                    <a className="event-btn btn-next" href="event-list.html">Next <i className="flaticon-next" /></a>
                </div> */}
          </div>
        </div>
      )}
      {/* Event List Page Start */}
      <Footer />
    </div>
  );
};

export default PressReleases;
