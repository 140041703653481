import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import * as config from "../config";
import useRazorpay from "react-razorpay";
import { LoadingOverlay, Alert } from "../Components/Elements";

function StallRegisterForm() {
  const [Razorpay] = useRazorpay();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [responseMessage, setResponseMessage] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(0);

  async function fetchStallData() {
    try {
      const response = await axios.get(
        `${config.API_URL}StallList`
      );
      const { data } = response.data;
      const optionData = data.map((stall) => ({
        value: parseInt(stall.stall_number), // Convert string to integer
        label: `${stall.stall_description}`,
        price: stall.stall_rate,
      }));
      setOptionList(optionData);
    } catch (error) {
      console.error("Error fetching stall data:", error);
    }
  }

  useEffect(() => {
    fetchStallData();
  }, []);

  const [formData, setFormData] = useState({
    exhibitor_name: "",
    exhibitor_address: "",
    contact_person_name: "",
    contact_person_number: "",
    contact_person_email: "",
    exhibitor_contact_number: "",
    exhibitor_whatsapp_number: "",
    exhibitor_email: "",
    exhibitor_company_name: "",
    exhibitor_website: "",
    sign_text: "",
    stall_numbers: "",
    no_stall: "",
    price_stall: "",
    total_amount: "",
  });

  const clearResponse = () => {
    setTimeout(() => {
      setResponseStatus(null);
      setResponseMessage(null);
    }, 2000);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };

  function handleSelect(data) {
    const ids = data.map((item) => item.value);
    const prices = data.map((item) => item.price);
    const sum = prices.reduce((acc, price) => acc + price, 0);

    setFormData((prevData) => ({
      ...prevData,
      stall: data,
      total_amount: sum,
      price_stall: prices,
      stall_numbers: ids,
    }));

    setSelectedOptions(ids);

    console.log(formData);
  }

  const disabledOptions = optionList.filter((option) =>
    selectedOptions.includes(option.value)
  );

  const selectableOptions = optionList.filter(
    (option) => !selectedOptions.includes(option.value)
  );

  const handlePayment = async (order_id) => {
	setLoadingStatus(1)
    const options = {
      key: "rzp_live_IsQtEDTsrfrHyi", // Enter the Key ID generated from the Dashboard
      currency: "INR",
      name: "Education Expo",
      description: "Test Transaction",
      image: "https://www.educationexpo.org/assets/logo/logo.png",
      order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        if (response.error && response.error.code === "payment.cancelled") {
			setLoadingStatus(0)
          axios.get(`${config.API_URL}OrderDelete?order_id=${order_id}`);
          alert("Payment was cancelled.");
          setResponseStatus(false);
          setResponseMessage("Payment was cancelled.");
          clearResponse();
        } else {
          axios
            .get(`${config.API_URL}StallOrderConfirm?order_id=${order_id}`)
            .then((response) => {
              // Initiate the file download using XMLHttpRequest or fetch
              const xhr = new XMLHttpRequest();
              xhr.open(
                "GET",
                `${config.API_URL}GenerateReciept?order_id=${order_id}`,
                true
              );
              xhr.responseType = "blob"; // Set the response type to blob (binary data)
              xhr.onload = () => {
                const blob = xhr.response;
                const downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = `Stall Reciept ${order_id}.pdf`;
                downloadLink.click();
                setResponseStatus(true);
                setResponseMessage("Registration Successful");
                clearResponse();
                setLoadingStatus(0);
				window.location.reload();
              };
              xhr.send();
            });
        }
      },
      modal: {
        ondismiss: () => {
          console.log(`cancel`);
          axios.get(`${config.API_URL}StallOrderCancel?order_id=${order_id}`);
          alert("Payment was cancelled.");
        },
      },
      notes: {
        address: "Education Expo",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);
    rzp1.open();
  };

  const handleSubmit = async () => {

    try {
      const data = await fetch(`${config.API_URL}StallRegistration`, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: JSON.stringify(formData), // Assuming formData is an object
      });
      const res = await data.json();
      console.log(`res`, res);
      fetchStallData();
      if (res.success == true) {
        handlePayment(res.order_id);
      } else {
		if(res.validate){
			const inputElements = document.querySelectorAll(`input[name="${res.param}"]`);

			inputElements.forEach((inputElement) => {
			  inputElement.style.border = "1px solid red";
			});
			setTimeout(() => {
				inputElements.forEach((inputElement) => {
				  inputElement.style.border = ""; // Remove the border by setting it to an empty string
				});
			  }, 2000);
		}
        setResponseStatus(false);
        setResponseMessage(res.message);
        clearResponse();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button
        className="btn btn-primary"
        style={{ margin: "30px 0", width: "100%" }}
        onClick={handleShow}
      >
        Book your Stall now
      </Button>
      <Modal size="xl" show={show} onHide={handleClose} scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>Stall Registration Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Company Name</label>
            <input
              className="form-control"
              type="text"
              onChange={handleInputChange}
              placeholder="Name"
              name="exhibitor_name"
            />
          </div>
          <div className="form-group">
            <label>Address</label>
            <input
              className="form-control"
              type="text"
              onChange={handleInputChange}
              placeholder="Address"
              name="exhibitor_address"
            />
          </div>
		  <hr/>
          <div className="form-group">
            <label><b>Contact Person</b></label>
            <div className="row">
              <div className="col-12 col-md-4">
				<label>Name</label>
                <input
                  className="form-control mb-2"
                  type="text"
                  onChange={handleInputChange}
                  placeholder="Name"
                  name="contact_person_name"
                />
              </div>
              <div className="col-12 col-md-4">
			  <label>Contact Number</label>
                <input
                  className="form-control mb-2"
                  type="number"
                  onChange={handleInputChange}
                  placeholder="Number"
                  name="contact_person_number"
                />
              </div>
              <div className="col-12 col-md-4">
			  <label>Email</label>
                <input
                  className="form-control mb-2"
                  type="email"
                  onChange={handleInputChange}
                  placeholder="Email"
                  name="contact_person_email"
                />
              </div>
            </div>
          </div>
	<hr/>
          <div className="form-group">
            <label><b>Company Info</b></label>
            <div className="row">
              <div className="col-12 col-md-4">
			  <label>Contact Number</label>
                <input
                  className="form-control mb-2"
                  type="number"
                  onChange={handleInputChange}
                  placeholder="Phone"
                  name="exhibitor_contact_number"
                />
              </div>
              <div className="col-12 col-md-4">
			  <label>Whatsapp Number</label>
                <input
                  className="form-control mb-2"
                  type="number"
                  onChange={handleInputChange}
                  placeholder="Mobile"
                  name="exhibitor_whatsapp_number"
                />
              </div>
              <div className="col-12 col-md-4">
			  <label>Email</label>
                <input
                  className="form-control mb-2"
                  type="email"
                  onChange={handleInputChange}
                  placeholder="Email"
                  name="exhibitor_email"
                />
              </div>
            </div>
          </div>
		  <hr/>
          <div className="form-group">
            <label>Stall</label>
            <Select
              options={selectableOptions}
              placeholder="Select Stalls"
              onChange={handleSelect}
            //   onClick={fetchStallData}
              isSearchable={true}
              isMulti
            />
          </div>
          <div className="form-group">
            <table class="table">
              <thead>
                <tr>
                  <th>Stall no</th>
                  <th>Stall Price</th>
                </tr>
              </thead>
              <tbody>
                {formData.stall ? (
                  formData.stall.map((e, index) => (
                    <tr key={index}>
                      <td>{e.label}</td>
                      <td>{e.price}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">No stall Selected</td>
                  </tr>
                )}

                <tr>
                  <th>Total Amount</th>
                  <td>{formData.total_amount || "0"}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="form-group">
            <label>Website</label>
            <input
              className="form-control"
              onChange={handleInputChange}
              placeholder="Website"
              name="exhibitor_website"
            />
          </div>
          <div className="form-group">
            <label>Inscription in English/Urdu to be put on the Facia</label>
            <input
              className="form-control"
              onChange={handleInputChange}
              placeholder="Sign Text"
              name="sign_text"
            />
          </div>
          {loadingStatus == 1 ? (
            <LoadingOverlay
              msg=" <p>Please wait while we book your stall and prepare your Receipt.</p>
						<p>Do not close this tab.</p>
						<p>Your Receipt will be automatically downloaded and sent to the email you provided.</p>"
            />
          ) : (
            ""
          )}{" "}
          {responseStatus !== null && (
            <Alert
              msg={responseMessage}
              type={responseStatus == true ? "success" : "danger"}
            />
          )}
          <br />
          <Button variant="primary" onClick={handleSubmit}>
            {" "}
            Submit{" "}
          </Button>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default StallRegisterForm;
