import React from 'react';
import { NavLink } from 'react-router-dom';
import * as config from "../config";
import { useEffect } from "react";

export const Alert = ({msg, type}) => {
    return (
        <div className={`alert alert-${type}`}>{msg}</div>
    );
};
export const Loading = ({msg}) => {
    return (
        <div><center><img src={'/assets/images/loading.gif'}/> {msg}</center></div>
    );
}

export const LoadingOverlay = ({msg})=>{


	useEffect(() => {
		// Function to disable scrolling
		const disableScroll = () => {
		  document.body.style.overflow = "hidden";
		};

		// Function to enable scrolling
		const enableScroll = () => {
		  document.body.style.overflow = "auto";
		};

		// Disable scrolling when the component mounts
		disableScroll();

		// Enable scrolling when the component unmounts (cleanup)
		return () => {
		  enableScroll();
		};
	  }, []);

	const createMarkup = () => {
		return { __html: msg }; // This sets the HTML content of the element
	  };
	  const overlayStyles = {
		position: "fixed",
		top: "0",
		left: "0",
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		display: "flex",
		zIndex: "100000",
		justifyContent: "center",
		alignItems: "center",
	  };

	  const textStyles = {
		color: "white", // Make text white
		fontSize: "20px", // Increase text size
		textAlign: "center", // Center text horizontally
	  };

	  return (
		<>
		  <div style={overlayStyles}>
			<center>
			  <i className="fa fa-spinner fa-spin fa-3x" /> {/* Add your icon here */}
			  <br />
			  <div
				dangerouslySetInnerHTML={createMarkup()}
				style={textStyles} // Apply text styles
			  />
			</center>
		  </div>
		</>
	  );
}
export const SmallActivityLabel = ({title, date, slug}) => {
    return <div>
        <NavLink to={`/ActivityDetail/${slug}`} replace><div className="single-details">
            <div className="label">{title}</div>
            <p className="details"><i className="far fa-clock" /> {date}</p>
        </div></NavLink>
    </div>
}
export const SmallBlogLabel = ({title, date, slug}) => {
    return <div>
        <NavLink to={`/Blog/${slug}`} replace><div className="single-details">
            <div className="label">{title}</div>
            <p className="details"><i className="far fa-clock" /> {date}</p>
        </div></NavLink>
    </div>
}


export const HeroHeader = ({title}) => {
    return (
        <div className="section exvent-hero-section d-lg-flex d-block align-items-center inner-page-hero" style={{backgroundImage: '', height: 350, paddingTop: 185}}>
            <img className="shape-1 img-fluid" src="assets/images/shape/hero_shape1.png" alt="" />
            <img className="shape-2 img-fluid" src="assets/images/shape/hero_shape2.png" alt="" />
            <div className="container">
            <div className="row exvent-hero-row justify-content-center">
                <div className="col-lg-8 text-center">
                <div className="page-title">
                    <h2 className="section-title">{title}</h2>
                </div>
                <div className="page-navigation">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item"><a href="\">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">{title}</a></li>
                    </ol>
                    </nav>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
};
