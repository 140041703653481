import React from 'react';
import * as config from '../config'
import { HeroHeader } from "../Components/Elements";
import Footer from "../Components/Footer";

const Gallery = () => {

	var images = ['assets/Gallery/Gallery (1).jpg',
	'assets/Gallery/Gallery (2).jpg',
	'assets/Gallery/Gallery (3).jpg',
	'assets/Gallery/Gallery (4).jpg',
	'assets/Gallery/Gallery (5).jpg',
	'assets/Gallery/Gallery (6).jpg',
	'assets/Gallery/Gallery (7).jpg',
	'assets/Gallery/Gallery (8).jpg',
	'assets/Gallery/Gallery (9).jpg',
	'assets/Gallery/Gallery (10).jpg',
	'assets/Gallery/Gallery (11).jpg',
	'assets/Gallery/Gallery (12).jpg',
	'assets/Gallery/Gallery (13).jpg',
	'assets/Gallery/Gallery (14).jpg',
	'assets/Gallery/Gallery (15).jpg',
	'assets/Gallery/Gallery (16).jpg',
	'assets/Gallery/Gallery (17).jpg',
	'assets/Gallery/Gallery (18).jpg',
	'assets/Gallery/Gallery (19).jpg',
	'assets/Gallery/Gallery (20).jpg',
	'assets/Gallery/Gallery (21).jpg',
	'assets/Gallery/Gallery (22).jpg',
	'assets/Gallery/Gallery (23).jpg',
	'assets/Gallery/Gallery (24).jpg',
	'assets/Gallery/Gallery (25).jpg',
	'assets/Gallery/Gallery (26).jpg',
	'assets/Gallery/Gallery (27).jpg',
	'assets/Gallery/Gallery (28).jpg',
	'assets/Gallery/Gallery (29).jpg',
	'assets/Gallery/Gallery (30).jpg',
	'assets/Gallery/Gallery (31).jpg',
	'assets/Gallery/Gallery (32).jpg',
	'assets/Gallery/Gallery (33).jpg',
	'assets/Gallery/Gallery (34).jpg',
	'assets/Gallery/Gallery (35).jpg',]
  return (
	<>
	  <HeroHeader title="Gallery" />
    <div className="gallery-area-2  section-padding-04">
      <div className="container">
        <div className="row">
{/* 	<div className="col-md-4">
  <a href="assets/images/gallery5.jpg" className="single-gallery" data-lightbox="library">
    <img src="assets/images/gallery5.jpg" alt="gallery" />
    <div className="gallery-text">
      <span><i className="fas fa-search-plus" /></span>
    </div>
  </a>
</div> */}

          {images.map((image, index) => (
            <div key={index} className="col-md-4 mb-4">
              <a
                href={image}
                className="single-gallery"
                data-lightbox="library"
              >
                <img src={ image} alt="gallery" className="img-fluid" />
                <div className="gallery-text">
                  <span>
                    <i className="fas fa-search-plus" />
                  </span>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
	<Footer />
	</>
  );
};

export default Gallery;
