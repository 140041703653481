import React from 'react';
// import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import TwitterTimeline from '../Components/TwitterImbed';
import FacebookEmbeddedProfile from '../Components/FacebookImbed';
var i = 0;
class Home extends React.Component{

  componentDidMount(){

    document.title = "Aurangabad Education Expo 2023";
    if (typeof window.loadMainJS === 'function') {
      window.loadMainJS();
    }
    if (typeof window !== 'undefined' && typeof window.AOS !== 'undefined') {
      window.AOS.init();
    }
    // window.scrollTo(0, 0);
  }
  componentDidUpdate(prevProps) {
    // Reinitialize AOS when the component updates (e.g., when you navigate back to it)
    if (
      this.props.location !== prevProps.location &&
      typeof window !== 'undefined' &&
      typeof window.AOS !== 'undefined'
    ) {
      window.AOS.refresh();
    }
  }
  render(){
    return <div>
    {/* <Navigation /> */}
    {/* Hero Start */}
    <div className="section exvent-hero-section d-lg-flex d-block align-items-center" style={{backgroundImage: ''}}>
      <img className="shape-1 img-fluid" src="assets/images/shape/hero_shape1.png" alt="" />
      <img className="shape-2 img-fluid" src="assets/images/shape/hero_shape2.png" alt="" />
      <div className="container">
        <div className="row exvent-hero-row">
          <div className="col-lg-6">
            {/* Hero Content Start */}
            <div className="hero-content">
              <h3 className="sub-title" data-aos="fade-down" data-aos-delay={600}>
                December 2023 </h3>
              <h2 className="title" data-aos="fade-left" data-aos-delay={800}>Aurangabad
                Education Expo 2023</h2>
              <div className="event-address" data-aos="fade-up" data-aos-delay={1000}>
                <h5>Aaam Khas Maidan, Aurangabad, MS</h5>
              </div>
              <br/><br/>
              <div className="hero-btn">
                <a className="btn-hero" href="/Stalls" data-aos="fade-right" data-aos-delay={1500}>Book Your Stall Now</a>
              </div>
              <div className="shape-3">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="459.5px" height="532.5px">
                  <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="3px" strokeLinecap="butt" strokeLinejoin="miter" fill="none" d="M456.500,364.422 C456.500,456.306 456.500,529.499 456.500,529.499 L1.500,529.499 L1.500,1.499 L456.500,1.499 C456.500,1.499 456.500,71.458 456.500,160.447 " />
                </svg>
              </div>
            </div>
            {/* Hero Content End */}
          </div>
        </div>
      </div>
      {/* Hero Image Start */}
      <div className="hero-images">
        <div className="images" data-aos="fade-left" data-aos-delay={500}>
          <img src="assets/images/2023/gate.png"  width={737}  alt="Aurangabad Education Expo 2023" />
        </div>
      </div>
      {/* Hero Image ennd */}
    </div>
    {/* Hero End */}
    {/* Event Counter Start */}
    <div className="event-counter-section section-padding grey-bg">
      <img src="assets/images/shape/counter_shape1.png" alt="shape1" className="conter-shape1" />
      <img src="assets/images/shape/counter_shape2.png" alt="shape2" className="conter-shape2" />
      <img src="assets/images/shape/counter_shape3.png" alt="shape3" className="conter-shape3" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-12">
            <div className="section-title">
              <h5 className="sub-title">7 Days - Aurangabad Education Expo</h5>
              <h2 className="title">Ignite your curiosity. Find inspiration. Shape your future</h2>
            </div>
          </div>
          <div className="col-lg-8 col-12">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-4 col-8">
                <div className="counter-item">
                  <h1 className="counter-numb counter color1">500000</h1>
                  <span>Visitors</span>
                </div>
              </div>
              <div className="col-md-4 col-8">
                <div className="counter-item">
                  <h1 className="counter-numb counter color2">200<br/></h1>
                  <span>Exhibitors</span>
                </div>
              </div>
              <div className="col-md-4 col-8">
                <div className="counter-item">
                  <h1 className="counter-numb counter color3">28</h1>
                  <span>Programs</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Event Counter End */}
    {/* About Area Start */}
    <div className="about-section section-padding-03">
      <div className="container">
        <div className="row g-0">
          <div className="col-lg-5 order-lg-1 order-2">
            <div className="about-thumb-wrap">
              <img src="assets/images/an_event_1.jpeg" alt="about_img1" width={653} />
              <img src="assets/images/an_event_2.jpeg" alt="Aurangabad Education Expo 2023" width={622} />
            </div>
          </div>
          <div className="col-lg-7 order-lg-1 order-1">
            <div className="about-content-wrap">
              <img src="assets/images/shape/about_content_shape1.png" alt="shape" className="about-box-shape" />
              <img src="assets/images/shape/about_shape1.png" alt="shape x" className="about-shape-x" />
              <div className="section-title">
                <h5 className="sub-title">An event for</h5>
                <h2 className="title">Get curious. Be inspired. Create your future.</h2>
              </div>
              <div className="section-paragraph">
                <p>Welcome to Aurangabad Education Expo - 2023! Prepare for a transformative educational journey that opens doors to boundless opportunities. As a leading regional event, AEE-2023 unites students, educators, and industry professionals to delve into the forefront of education. Explore the latest trends, innovations, and advancements in the field through diverse activities, workshops, and interactive sessions. </p>
                <p>Our aim is to inspire, empower, and equip you with the knowledge and skills necessary to forge a prosperous future. Join us and become part of an extraordinary educational experience at AEE-2023, where limitless possibilities await. </p>
              </div>
              <div className="about-btn">
                <a className="btn" href="/Contact">Contact for booking</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* About Area End */}

    {/* Schedule Area End */}
    {/* Pricing Area Start */}
    <div className="pricing-area section-padding-04" style={{backgroundImage: 'url(assets/images/bg/price_bg.jpg)'}}>
      <div className="pricing-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title-wrap">
                <div className="section-title text-center">
                  <h5 className="sub-title">Event Pricing</h5>
                  <h2 className="title white">Book your stall now</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-7">
              <div className="price-card text-center">
                <div className="price-header">
                  <span className="price-plan">Premium Stall</span>
                  <h3 className="price-ammount"><sup>₹</sup>25,000/-</h3>
                </div>
                <div className="price-body">
                  <ul className="price-desc">
                  <li>Standard Size: 3mx3m</li>
                    <li>Table with Cover (2 Nos)</li>
                    <li>Plastic Chair (2 Nos)</li>
                    <li>Power Point (1 Nos)</li>
                    <li>Tube Lights (2 Nos)</li>
                    <li>Rackes (2 Nos)</li>
                    <li>12 Hrs. Electricity Supply</li>
                    <li>Stall Closing Cloth (3x3 mtr.)</li>
                  </ul>
                </div>
                <div className="price-footer">
                  <a href="/Stalls" className="btn price-btn btn-blue"> Book a stall</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-7">
              <div className="price-card text-center">
                <div className="price-header pink-bg">
                  <span className="price-plan">Commercial Exibition Stalls</span>
                  <h3 className="price-ammount"><sup>₹</sup>18,500/-</h3>
                </div>
                <div className="price-body">
                  <ul className="price-desc">
                  <li>Standard Size: 3mx3m</li>
                    <li>Table with Cover (2 Nos)</li>
                    <li>Plastic Chair (2 Nos)</li>
                    <li>Power Point (1 Nos)</li>
                    <li>Tube Lights (2 Nos)</li>
                    <li>-</li>
                    <li>12 Hrs. Electricity Supply</li>
                    <li>Stall Closing Cloth (3x3 mtr.)</li>
                  </ul>
                </div>
                <div className="price-footer">
                  <a href="/Stalls" className="btn price-btn"> Book a stall</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-7">
              <div className="price-card text-center">
                <div className="price-header yellow-bg">
                  <span className="price-plan">Food Stalls</span>
                  <h5 className="price-ammount">Contact</h5>
                </div>
                <div className="price-body">
                  <ul className="price-desc">
                    <li>Standard Size: 3mx3m</li>
                    <li>Table with Cover (2 Nos)</li>
                    <li>Plastic Chair (2 Nos)</li>
                    <li>Power Point (1 Nos)</li>
                    <li>Tube Lights (2 Nos)</li>
                    <li>-</li>
                    {/* <li>Washing Area - Common</li>
                    <li>Water Supply</li> */}
                    <li>12 Hrs. Electricity Supply</li>
                    <li>Stall Closing Cloth (3x3 mtr.)</li>
                  </ul>
                </div>
                <div className="price-footer">
                  <a href="/Stalls" className="btn price-btn btn-yellow"> Book a stall</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Pricing Area End */}
    {/* Brand Area Start */}
    <div className="brand-area section-padding-05" style={{display:'none'}}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title-wrap">
              <div className="section-title text-center">
                <h5 className="sub-title">Sponsors</h5>
                <h2 className="title">50+ leading companies with us</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-3 col-6">
            <div className="brand-item">
              <a href="#"><img src="assets/images/logo/brand1.png" alt="Brand" /></a>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="brand-item">
              <a href="#"><img src="assets/images/logo/brand2.png" alt="Brand" /></a>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="brand-item">
              <a href="#"><img src="assets/images/logo/brand3.png" alt="Brand" /></a>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="brand-item">
              <a href="#"><img src="assets/images/logo/brand4.png" alt="Brand" /></a>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="brand-item">
              <a href="#"><img src="assets/images/logo/brand5.png" alt="Brand" /></a>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="brand-item">
              <a href="#"><img src="assets/images/logo/brand6.png" alt="Brand" /></a>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="brand-item">
              <a href="#"><img src="assets/images/logo/brand7.png" alt="Brand" /></a>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="brand-item">
              <a href="#"><img src="assets/images/logo/brand8.png" alt="Brand" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Brand Area End */}



    {/* <div className="event-counter-section section-padding grey-bg">
      <img src="assets/images/shape/counter_shape1.png" alt="shape1" className="conter-shape1" />
      <img src="assets/images/shape/counter_shape2.png" alt="shape2" className="conter-shape2" />
      <img src="assets/images/shape/counter_shape3.png" alt="shape3" className="conter-shape3" />
          <div className="container">
            <div className="row" style={{marginBottom: "30px"}}>
              <div className="col-12">
                <div className="section-title-wrap">
                  <div className="section-title text-center">
                  <h5 className="sub-title">Social Media</h5>
                  <h2 className="title">Follow the event</h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <TwitterTimeline/>
              </div>
              <div className="col-md-6">
                <FacebookEmbeddedProfile profileUrl="https://www.facebook.com/eduexpoindia" />
              </div>

            </div>
          </div>
    </div> */}



    {/* Past Event Gallery Area Start */}
    <div className="past-event-gallery-area section-padding-06 ">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title-wrap">
              <div className="section-title text-center">
                <h5 className="sub-title">Previous Gallery</h5>
                <h2 className="title">Past event memories</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-md-3 col-sm-6">
            <a href="#" className="single-gallery">
              <img src="assets/images/2022/entrance.jpeg" alt="gallery" />
              <div className="gallery-content">
                <span>Dec</span>
                <h5>2022</h5>
              </div>
            </a>
          </div>
          <div className="col-md-3 col-sm-6">
            <a href="#" className="single-gallery">
              <img src="assets/images/2022/02.jpeg" alt="gallery" />
              <div className="gallery-content">
                <span>Dec</span>
                <h5>2022</h5>
              </div>
            </a>
          </div>
          <div className="col-md-3 col-sm-6">
            <a href="#" className="single-gallery">
              <img src="assets/images/2022/04.jpeg" alt="gallery" />
              <div className="gallery-content">
                <span>Dec</span>
                <h5>2022</h5>
              </div>
            </a>
          </div>
          <div className="col-md-3 col-sm-6">
            <a href="#" className="single-gallery">
              <img src="assets/images/2022/03.jpeg" alt="gallery" />
              <div className="gallery-content">
                <span>Dec</span>
                <h5>2022</h5>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>;

  }
}

export default Home;
