import React, {useState,useEffect} from 'react';
import {Card} from '../Components/Cards'
import * as config from '../config';
import {HeroHeader, Loading} from '../Components/Elements';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import { Link, NavLink } from "react-router-dom";
const PressMaterials = () =>{
    const [rows, setRows] = useState([]);
    const [loadingAPI, setLoadingAPI] = useState(false);
    useEffect(() => {
        document.title = "Press Releases - Aurangabad Education Expo 2023";
        if (typeof window.loadMainJS === 'function') {
          window.loadMainJS();
        }
        // window.scrollTo(0, 0);

      }, []);

    useEffect(() => {
        setLoadingAPI(true);
        fetch(`${config.API_URL}PressMaterialList`, {
            method: 'GET',
            headers: {"Content-Type": "application/x-www-form-urlencoded"}
          })
          .then(response => response.text())
          .then(data => {
            var rows = JSON.parse(data).data;
            setRows(rows);
            setLoadingAPI(false);
          })
          .catch(error => {
            // Handle any errors
            console.error(error);
          });
    },[]);
    return (

        <div>
             {/* <Navigation /> */}
            {/* Hero Start */}
            <HeroHeader title="Press Materials" />
            {/* Hero End */}

            {/* Event List Page Start */}
            {
            loadingAPI == true?<div style={{padding: 100}}><Loading msg={`Please wait while we loading Press Releases`}/></div>:
            <div className="event-list-page-wrap">
                <div className="container" style={{paddingTop:20, paddingBottom: 20}}>

                {/* Event List Content Start */}
                <div className="event-list-content-wrap">
                    <div className="tab-content">
                    <div className="tab-pane  show active" id="grid">
                        <div className="row">
                        {rows && rows.map((item, index) => (
                            <Card key={item.pm_id} img={item.pm_file} title={item.pm_title} timing={item.created_date} location={`Aurangabad`} description={`-`} url={item.pm_file} />
                        ))}
                        </div>
                    </div>

                    </div>
                </div>
                {/* Event List Content End */}
                {/* <div className="event-next-prev-btn text-center">
                    <a className="event-btn" href="event-list.html"><i className="flaticon-back" /> Previous</a>
                    <a className="event-btn btn-next" href="event-list.html">Next <i className="flaticon-next" /></a>
                </div> */}
                </div>
            </div>}
            {/* Event List Page Start */}
            <Footer />
        </div>
    );

}

export default PressMaterials;
