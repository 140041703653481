import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as config from './../config';

const Footer = () => {const [email, setEmail] = useState('');
const [subscribed, setSubscribed] = useState(false);
const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
const [isSubscribing, setIsSubscribing] = useState(false);
const [alreadySubscribed, setAlreadySubscribed] = useState(false);

const handleEmailChange = (e) => {
  setEmail(e.target.value);
};

const handleSubscribe = () => {
  if (subscribed) {
    // User is already subscribed
    setAlreadySubscribed(true);
    return;
  }

  setIsSubscribing(true); // Set the subscribing state to true

  // Make a POST request to the API endpoint
  fetch(`${config.API_URL}subscription?email=${email}`, {
    method: 'GET',
  })
    .then((response) => {
      if (response.status === 200) {
        console.log(response);
        setSubscribed(true);
        setSubscriptionSuccess(true);
      } else {
        // Handle subscription error (e.g., display an error message)
        console.error('Subscription failed');
        setAlreadySubscribed(true);
        return;
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    })
    .finally(() => {
      setIsSubscribing(false); // Set the subscribing state back to false
    });
};

  return (
    <div>
      {/* Footer Section Start */}
      <div className="footer-section" style={{ backgroundImage: 'url(assets/images/footer_back.jpeg)' }}>
        <div className="container">
          <div className="footer-newsletter">
            <div className="row">
              <div className="col-lg-5 col-12 text-sm-start text-center">
                <div className="footer-title">
                  <span>Subscribe Newsletter</span>
                  <h4>Subscribe to receive daily news</h4>
                </div>
              </div>
              <div className="col-lg-7 col-12">
                <div className="footer-newsletter-form">
                  <input type="text" placeholder="Your Email" id='subscribedemail' value={email} onChange={handleEmailChange} />
                  {subscriptionSuccess ? (
                    <button className="btn btn-success" disabled>
                      Subscribed <i className="fas fa-check" />
                    </button>
                  ) : (
                    <>
                      {alreadySubscribed ? (
                        <div className="already-subscribed-message">Already Subscribed</div>
                      ) : (
                        <button className={`btn btn-primary ${isSubscribing ? 'subscribing' : ''}`} onClick={handleSubscribe}>
                          {isSubscribing ? (
                            <>
                              Subscribing... <i className="fas fa-spinner fa-spin" />
                            </>
                          ) : (
                            'Subscribe'
                          )}
                        </button>
                      )}
                        </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-widget-social">
          <div className="container">
            <div className="row text-center">
              <div className="col-12">
                <div className="social-title">
                  <h4 className="title">Contact With us</h4>
                </div>
              </div>
              <div className="col-12">
                <ul className="social-list">
                  <li><a target="_blank" href="https://www.facebook.com/eduexpoindia"><i className="fab fa-facebook-f" /></a></li>
                  <li><a target="_blank" href="https://twitter.com/eduexpoindia"><i className="fab fa-twitter" /></a></li>
                  <li><a target="_blank" href="https://www.youtube.com/@eduexpoindia"><i className="fab fa-youtube" /></a></li>
                  <li><a target="_blank" href="https://www.instagram.com/eduexpoindia"><i className="fab fa-instagram" /></a></li>
                  <li><a target="_blank" href="https://www.linkedin.com/company/eduexpoindia"><i className="fab fa-linkedin" /></a></li>
                  <li><a target="_blank" href="https://www.pinterest.com/eduexpoindia"><i className="fab fa-pinterest" /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-widget-navigation text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer-navigation">
                  <ul>
                    <li><NavLink to="/">Home</NavLink></li>
                    <li><NavLink to="/About" replace>About Us</NavLink></li>
                    <li><NavLink to="/Blogs" replace>Blogs</NavLink></li>
                    {/* <li><NavLink to="/Contact" replace>Sponsors</NavLink></li> */}
                    {/* <li><NavLink to="/Schedules" replace>Schedule</NavLink></li> */}
                    <li><NavLink to="/Contact" replace>Contact</NavLink></li>
                  </ul>
                  <ul>
                    <li><NavLink to="/TermsAndConditions" replace>Terms &amp; Conditions</NavLink></li>
                    <li><NavLink to="/SponsorshipPolicy" replace>Sponsership Policies</NavLink></li>
                    <li><NavLink to="/PrivacyPolicy" replace>Privacy Policy</NavLink></li>
                    <li><NavLink to="/RefundPolicy" replace> Refund Policy</NavLink></li>
                  </ul>
                  <ul>
				  <li><NavLink to="/Downloads" replace> Downloads</NavLink></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Copyright Start */}
        <div className="footer-copyright-area">
          <div className="container">
            <div className="footer-copyright-wrap">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  {/* Footer Copyright Text Start */}
                  <div className="copyright-text text-center">
                    <p>2023 Copyright Aurangabad Education Expo. All Rights Reserved | An Initiative By <a href="http://bilimtechnologies.com/">BILIM TECHNOLOGIES PRIVATE LIMITED</a></p>
                  </div>
                  {/* Footer Copyright Text End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Section End */}
    </div>
  );
};

export default Footer;
