import {HeroHeader, Loading} from '../Components/Elements';
const Downloads = ()=>{
	return (
		<>
<HeroHeader title="Downloads" />
	<div className="faq-area section-padding-06">
  <img src="assets/images/bg/map_bg.png" alt="Map BG" className="bg-map img-fluid" />
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-8">
        <div className="section-title-wrap">
          <div className="section-title text-center">
            <h5 className="sub-title orange">Downloads</h5>
            <h2 className="title">Resources and Press Material</h2>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="accordion accordion-flush" id="faq_accordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="accordion_1">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
               Science Fair Topics Suggestions
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="accordion_1" data-bs-parent="#faq_accordion">
              <div className="accordion-body">
                <p>Science Fair Project Ideas
based on CBSE curriculum for grades 6 to 10 in India
These projects can be conducted at home with minimal or no special lab
equipment.</p>
				<a _target="blank" className='btn' href="/downloads/SciFair Project Ideas.pdf">Download</a>


              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="accordion_1">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
               Student Registration Form
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse " aria-labelledby="accordion_1" data-bs-parent="#faq_accordion">
              <div className="accordion-body">
                <p>Student Registration Form that can be used to submit offline Registrations.</p>
				<a _target="blank" className='btn' href="/downloads/Student Registration Form.pdf">Download</a>


              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
</>
	)
}


export default Downloads;
