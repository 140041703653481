import React from 'react';
import Navigation from '../../Components/Navigation'; 
import Footer from '../../Components/Footer';
import {HeroHeader, Loading} from '../../Components/Elements';
var i = 0;
class RefundPolicy extends React.Component{
  componentDidMount(){
    
    document.title = "Refund  Policy - Aurangabad Education Expo 2023";
    window.scrollTo(0, 0);
  }
  render(){
    return (<div>
      <Navigation />
      {/* Hero Start */}
      <HeroHeader title="Refund Policy for Education Expo" />
      {/* Hero End */}
        
      <div className="about-section about-page section-padding-03">
        <div className="container">
        <h1>Refund Policy:</h1>
          <p>Thank you for visiting our website (www.educationexpo.org). This Refund Policy outlines the terms and conditions related to refunds for our services. Please read this policy carefully before making any purchases or transactions on our website.</p>
          <h2>Event Registration Fees:</h2>
          <ul>
            <li>All event registration fees are non-refundable.</li>
            <li>In case of cancellation of the event by the organizer, a full refund will be provided to the registered participants.</li>
          </ul>
          <h2>Products and Merchandise:</h2>
          <ul>
            <li>[Insert specific refund policy for products and merchandise here]</li>
          </ul>
          <h2>Membership and Subscription Fees:</h2>
          <ul>
            <li>Membership and subscription fees are non-refundable.</li>
            <li>In case of cancellation or termination of a membership or subscription, no refunds will be provided for the remaining duration of the subscription.</li>
          </ul>
          <p>Please note that all refund requests must be made within the specified timeframes mentioned above and should be submitted in writing to our customer support. Refunds will be processed based on the original method of payment, and any applicable fees or charges associated with the refund process may be deducted.</p>
          <h2>Stall Registration Fees:</h2>
          <ul>
            <li>All stall registration fees are non-refundable unless otherwise stated.</li>
            <li>In case of cancellation by the participant before the specified deadline, a refund may be provided, subject to deduction of any applicable administrative fees or charges.</li>
            <li>If the event is canceled by the organizer, a refund (as per situation) of the stall registration fees will be provided.</li>
          </ul>
          <p>We reserve the right to modify this Refund Policy at any time without prior notice. By using our website and making any purchases or transactions, you acknowledge and agree to this Refund Policy.</p>
          <p>If you have any questions or require further assistance regarding our refund policy, please contact our customer support.</p>
          <p>Email: <a href="mailto:Admin@EducationExpo.org">Admin@EducationExpo.org</a></p>
        </div></div>
        <Footer />
      </div>);
  }
}

export default RefundPolicy;





