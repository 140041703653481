import React from 'react';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';

class SponsorshipPolicy extends React.Component{
  componentDidMount(){

    document.title = "Sponsorship Policy - Aurangabad Education Expo 2023";
    // window.scrollTo(0, 0);
  }
  render(){
    return (<div>
       {/* <Navigation /> */}
      {/* Hero Start */}
      <div className="section exvent-hero-section d-lg-flex d-block align-items-center inner-page-hero" style={{backgroundImage: ''}}>
        <img className="shape-1 img-fluid" src="assets/images/shape/hero_shape1.png" alt="" />
        <img className="shape-2 img-fluid" src="assets/images/shape/hero_shape2.png" alt="" />
        <div className="container">
          <div className="row exvent-hero-row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="page-title">
                <h2 className="section-title">Sponsorship Policy</h2>
              </div>
              <div className="page-navigation">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item"><a href="\">Home</a></li>
                    <li className="breadcrumb-item"><a href="SponsorshipPolicy">Sponsorship Policy</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hero End */}
      <div className="about-section about-page section-padding-03">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title" style={{marginBottom: '20px'}}>
                  <h5 className="sub-title orange">Aurangabad Education Expo 2023</h5>
                  <h2 className="title">Sponsorship Policy for Education Expo 2023</h2>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="about-right-content">
                  <div className="section-paragraph">

                    <p>
                    <h5>Introduction:</h5>
                        The Sponsorship Policy outlines the guidelines and procedures for securing sponsorships for Education Expo 2023. This policy ensures transparency, fairness, and mutual benefits for both the event organizers and sponsors.</p>
                    <p>
                        <h5>Sponsorship Levels:</h5>
                        Education Expo 2023 offers multiple sponsorship levels to accommodate the diverse needs and budgets of potential sponsors. The sponsorship levels may include Title Sponsor, Powered by, Co-Sponsor, Associate Sponsor, and Supporting Sponsor, each offering different benefits and recognition.
                    </p>
                    <p>
                        <h5>Sponsorship Benefits:</h5>
                        Sponsorship benefits may vary based on the level of sponsorship. The benefits can include but are not limited to:
                        <br />- Brand exposure through logo placement on event collaterals, marketing materials, and advertisements.
                        <br />- Special mention in press releases, media coverage, and social media promotions.
                        <br />- Exclusive exhibition space or stall in a prime location at the event venue.
                        <br />- Opportunity to deliver a keynote speech or presentation during the event.
                        <br />- Logo inclusion on the event website, with a link to the sponsor's homepage.
                        <br />- Complimentary registrations for representatives to attend seminars, workshops, and conferences.

                    </p>
                    <p>
                        <h5>Sponsorship Packages:</h5>
                        Education Expo 2023 offers comprehensive sponsorship packages that outline the specific benefits and costs associated with each sponsorship level. The packages provide clear details on the deliverables, timeline, and payment terms. The organising committee will provide the sponsorship package details to potential sponsors upon request.

                    </p>
                    <p>
                        <h5>Application and Selection Process:</h5>
                        Interested sponsors must submit a formal application expressing their interest in sponsoring Education Expo 2023. The application should include relevant information about the sponsor's organisation, the desired sponsorship level, and any specific objectives or expectations. The organising committee will review the applications and select sponsors based on alignment with the event's objectives and the availability of sponsorship opportunities.
                    </p>
                    <p>
                        <h5>Customization of Sponsorship Benefits:</h5>
                        The organising committee is open to discussing and customising sponsorship benefits to meet the specific needs and objectives of sponsors. This allows sponsors to maximise their investment and achieve their desired outcomes from their partnership with Education Expo 2023.
                    </p>
                    <p>
                        <h5>Sponsorship Agreement:</h5>
                        Upon selection, sponsors will be required to sign a sponsorship agreement that outlines the terms and conditions of the sponsorship. The agreement includes details such as sponsorship fees, payment schedule, sponsorship benefits, obligations of both parties, and any exclusivity or non-compete clauses.
                    </p>
                    <p>
                        <h5>Sponsorship Recognition and Promotion:</h5>
                        The organising committee will ensure appropriate recognition and promotion of sponsors before, during, and after Education Expo 2023. This may include public acknowledgment during opening and closing ceremonies, signage and banners displaying sponsor logos, and integration of sponsor branding in event communications and materials.
                    </p>
                    <p>
                        <h5>Sponsorship Evaluation and Feedback:</h5>
                        The organising committee will seek feedback from sponsors to evaluate their satisfaction with the sponsorship experience. This feedback will help improve future sponsorship programs and enhance the overall value delivered to sponsors.
                    </p>
                    <p>
                        <h5>Amendments to the Policy:</h5>
                        The organising committee reserves the right to amend or update the Sponsorship Policy as necessary. Any changes will be communicated to sponsors in a timely manner.

                        <br />By adhering to the Sponsorship Policy, sponsors and the event organisers establish a mutually beneficial partnership that contributes to the success of Education Expo 2023.

                    </p>

                    <p>
                        <a href='#'><b>Merchandising Policy for Education Expo 2023</b></a> | <a href='#'><b>Terms & Conditions for "National Urdu Book Fair"</b></a>
                    </p>

                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
        <Footer />
    </div>);
  }
}

export default SponsorshipPolicy;

