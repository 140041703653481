import React, { useState, useEffect } from "react";
import * as config from "../config";
import { HeroHeader } from "../Components/Elements";
import Footer from "../Components/Footer";
import { useLocation, Link } from "react-router-dom";
import StallRegisterForm from "../Components/StallRegisterForm";
import StallMap from "./StallMap";
import StallGallery from "./StallGallery";

const Stalls = () => {
  const [tab, setTab] = useState("map");

  useEffect(() => {
    document.title = "Stalls - Aurangabad Education Expo 2023";
    if (typeof window.loadMainJS === "function") {
      window.loadMainJS();
    }
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return (
    <div>
      <style>
        {`
		.hidden-tab {
			display: none!IMPORTANT;
		}
        .pricing-area::after {
          background: white;
        }
      `}
      </style>
      {/* <Navigation /> */}
      <HeroHeader title="Stalls" />

      <div className="schedule-area section-padding-02 grey-bg">
        <div className="container">
          <div className="row"></div>
          <div className="row g-0 align-items-start">
            <div className="col-2 sticky-col">
              <div className="schedule-side-nav">
                <div
                  className="nav d-block nav-pills me-3"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <p
                    className="nav-link active"
                    id="day-1-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-map"
                    role="tab"
                    aria-controls="v-pills-map"
                    aria-selected="true"
                    onClick={() => setTab("map")}
                  >
                    Stall Map
                  </p>
                  <p
                    className="nav-link"
                    id="day-2-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-deliverables"
                    role="tab"
                    aria-controls="v-pills-deliverables"
                    aria-selected="false"
                  >
                    Deliverables
                  </p>
                  <p
                    className="nav-link"
                    id="day-3-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-tac"
                    role="tab"
                    aria-controls="v-pills-tac"
                    aria-selected="false"
                  >
                    Guidelines
                  </p>
                </div>
              </div>
            </div>
            <div className="col-10">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane show active"
                  id="v-pills-map"
                  role="tabpanel"
                >
                  <div className="schedule-wrapper py-4">
                    <center
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "100%",
                        height: "100px",
                      }}
                    >
                      <Link
                        to="../StallGallery"
                        className="btn"
                        style={{ margin: "0 20px", width: "50%" }}
                      >
                        Gallery
                      </Link>
                      <Link
                        to="../StallTour"
                        className="btn"
                        style={{ margin: "0 20px", width: "50%" }}
                      >
                        Tour
                      </Link>
                    </center>

                    <div id="map" className={tab !== "map" ? "hidden-tab" : ""}>
                      <StallMap />
                      <StallRegisterForm />
                    </div>

                    <div
                      id="gallery"
                      className={tab !== "gallery" ? "hidden-tab" : ""}
                    >
                      {/* <StallGallery/> */}
                    </div>

                    <div
                      id="tour"
                      className={tab !== "tour" ? "hidden-tab" : ""}
                    >
                      <iframe
                        src="https://7pebblesreportedtaste.surge.sh/360"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane"
                  id="v-pills-deliverables"
                  role="tabpanel"
                >
                  <div
                    className="pricing-area section-padding-02"
                    style={{ background: "white" }}
                  >
                    <div className="pricing-wrapper px-4">
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="section-title-wrap">
                              <div className="section-title text-center">
                                <h1 className="sub-title" style={{fontSize: "xx-large"}}>Deliverables</h1>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-lg-6 col-sm-12 px-5">
                            <div className="price-card text-center">
                              <div className="price-header">
                                <span className="price-plan">
                                  GERMEN HANGER DOME
                                </span>
                              </div>
                              <div className="price-body">
                                <ul className="price-desc">
                                  <li>Weather Proof Dome</li>
                                  <li>320 x 130 Covering Area</li>
                                  <li>Commen LED Focus</li>
                                  <li>CCTV Surveillance</li>
                                  <li>Floor Netting</li>
                                  <li>Rackes (2 Nos)</li>
                                  <li>Red Carpet on walking Area</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-12 px-5">
                            <div className="price-card text-center">
                              <div className="price-header pink-bg">
                                <span className="price-plan">
                                  OCTANORM STALLS
                                </span>
                              </div>
                              <div className="price-body">
                                <ul className="price-desc">
                                  <li>Standard Size : 3mx3m</li>
                                  <li>Table with Cover (2 Nos)</li>
                                  <li>Plastic Chair (2 Nos)</li>
                                  <li>Power Point (1 Nos)</li>
                                  <li>Tube Lights (2 Nos)</li>
                                  <li>Rackes (2 Nos) Book Fair Only</li>
                                  <li>12 Hrs. Electricity Supply</li>
                                  <li>Stall Closing Cloth (3x3 mtr.)</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="schedule-wrapper" style={{ minHeight: "120vh" }}>
                    <div className="exhibition-guidelines">
                      
                      <div className="row justify-content-center ">
                        <div className="col-lg-4">
                          <b>GERMEN HANGER DOME</b>
                          <ul>
                            <li>- Weather Proof Dome</li>
                            <li>- 320 x 130 Covering Area</li>
                            <li>- Commen LED Focus</li>
                            <li>- CCTV Surveillance</li>
                            <li>- Floor Netting</li>
                            <li>- Red Carpet on walking Area</li>
                          </ul>
                        </div>
                        <div className="col-lg-4">
                          <b>OCTANORM STALLS</b>
                          <ul>
                            <li>- Standard Size : 3mx3m</li>
                            <li>- Table with Cover (2 Nos)</li>
                            <li>- Plastic Chair (2 Nos)</li>
                            <li>- Power Point (1 Nos)</li>
                            <li>- Tube Lights (2 Nos)</li>
                            <li>- Rackes (2 Nos) Book Fair Only</li>
                            <li>- 12 Hrs. Electricity Supply</li>
                            <li>- Stall Closing Cloth (3x3 mtr.)</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="tab-pane" id="v-pills-tac" role="tabpanel">
                  <div className="schedule-wrapper px-4 pb-5 pt-3">
                    <div class="about-right-content">
                      <div class="section-paragraph px-4 pb-5">
                        <p class="pb-5">
                        <h3>Guidelines</h3>
                        <ul>
                          <li>
                            <span>01.</span> Standard Size of Stall: 3m X 3m
                            (Standard)
                          </li>
                          <li>
                            <span>02.</span> Last Date of Registration: 25th Nov
                            2022
                          </li>
                          <li>
                            <span>03.</span> Organizer’s Decision: Applications
                            received after the last date will be final.
                          </li>
                          <li>
                            <span>04.</span> Stall Condition: Stall must be
                            returned in its original condition post-exhibition.
                          </li>
                          <li>
                            <span>05.</span> Cleanliness: Maintain cleanliness
                            inside the stalls; Education Expo 2022 will handle
                            the rest.
                          </li>
                          <li>
                            <span>06.</span> Banners: Banners can be placed
                            inside the allotted stall area only.
                          </li>
                          <li>
                            <span>07.</span> Material Placement: All exhibition
                            or sale materials should be kept inside the
                            respective stalls.
                          </li>
                          <li>
                            <span>08.</span> Legal Compliance: No display or
                            storage against existing laws or national law.
                          </li>
                          <li>
                            <span>09.</span> Audio-Visual Media: Use
                            audio-visual media at your own cost without
                            disturbing others.
                          </li>
                          <li>
                            <span>10.</span> Event Permissions: Obtain prior
                            permission for special events.
                          </li>
                          <li>
                            <span>11.</span> Belongings: Take care of your
                            belongings.
                          </li>
                          <li>
                            <span>12.</span> Security: Education Expo 2022
                            provides round-the-clock security.
                          </li>
                          <li>
                            <span>13.</span> Cancellation: No refunds in case of
                            unforeseen circumstances.
                          </li>
                          <li>
                            <span>14.</span> Disputes: Matters subject to
                            Aurangabad Jurisdiction only.
                          </li>
                          <li>
                            <span>15.</span> Participant Cancellation: No
                            refunds for participant cancellations.
                          </li>
                          <li>
                            <span>16.</span> Stall Allocation: No participant
                            can exchange or transfer allocated stalls without
                            the committee's consent.
                          </li>
                        </ul>
                        </p>
                      </div>
                    </div>
                    <div className="single-schedule"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Stalls;
