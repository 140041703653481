
import { useState } from 'react';
import Slider from 'react-slick';
import { HeroHeader } from "../Components/Elements";
import Footer from "../Components/Footer";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Report = () => {
  const [sliderSettings] = useState({
    dots: true,
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  });
  return (
    <div>
      {/* <Navigation /> */}
      <HeroHeader
        title={
          "Executive Report: Aurangabad Education Expo 2023"
        }
      />

      <div className="event-single-page section-padding-02">
        <div className="container">
          <div className="row justify-content-between gx-5">
           

            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-sm-12">
            
                <div className="single-blog">
                  <div className="event-header"></div>
                  <div className="event-body">
                    <div className="event-details"></div>
                    <br />
                    <h4 className="event-title">Executive Report: Aurangabad Education Expo 2023</h4>

                   

                    <p>
        The Aurangabad Education Expo 2023, held from 15 December to 21 December 2023, marked a remarkable success,
        surpassing expectations and achieving significant milestones across various domains.
    </p>
    <br/>
    <h3>Key Metrics:</h3>

    <ul>
        <li><strong> - Footfall:</strong> Over 450,000 attendees engaged with the event, showcasing immense public
            interest and participation.</li>
        <li><strong> - Exhibition Stalls:</strong> Hosted 120 exhibition stalls, creating an engaging platform for diverse
            educational institutions and stakeholders.</li>
        <li><strong> - Book Fair Stalls:</strong> Featured 80+ book fair stalls, offering a rich literary landscape and
            fostering cultural exchange.</li>
        <li><strong> - Publisher Perception:</strong> Over 60 publishers gained valuable exposure, expanding their reach
            and visibility.</li>
        <li><strong> - School Engagement:</strong> Connected with 160+ schools and established collaborative partnerships
            with 60+ institutions, fostering educational networks.</li>
        <li><strong> - Student Participation:</strong> Witnessed an enrollment of 4000+ students in pre-event activities,
            highlighting active engagement and anticipation.</li>
        <li><strong> - Specialized Conferences:</strong> Successfully organized the Ulema Conference and NGO Conference,
            attracting 200+ Ulama and 100+ NGOs, respectively, from the Marathwada region.</li>
    </ul>
    <br/>
    <Slider {...sliderSettings}>

<img src={'./assets/01.jpg'} alt="" />
<img src={'./assets/02.jpg'} alt=""  />
<img src={'./assets/03.jpg'} alt=""  />
<img src={'./assets/04.jpg'} alt="" />
<img src={'./assets/05.jpg'} alt=""  />
<img src={'./assets/06.jpg'} alt=""  />

</Slider>

<br/>
    <h3>Panel Discussions and Workshops:</h3>

    <p>Conducted 21+ panel discussions, seminars, and workshops, engaging over 3000 teachers, students, and devotees,
        promoting discourse and knowledge exchange.</p>
        <br/>
    <h3>Recognition and Awards:</h3>

    <ul>
        <li>Recognized top-performing students of pre-event activities and awarded prizes, encouraging academic
            excellence.</li>
        <li>IRC's "Qur'an and Modern Science Exhibition" garnered participation from 150,000+ individuals, promoting
            intellectual discourse and education.</li>
        <li>The food zone received public acclaim for quality, service, and the introduction of innovative coupons,
            enhancing the overall attendee experience.</li>
    </ul>
    <br/>
    <h3>Literary Engagements:</h3>

    <ul>
        <li>Engaged with 72 Urdu writers through the "Musnaf Se Millay" program, facilitating enriching dialogues and
            literary exchanges.</li>
        <li>Collaborated with Karwan Urdu and Minds in Motion Foundation to honor Aurangabad's authors via the "Bazum
            Takreem Ahl Qalam" program, celebrating literary contributions.</li>
    </ul>
    <br/>
    <h3>Acknowledgment of Service:</h3>

    <p>Acknowledged and awarded principals from 60+ schools in Aurangabad, recognizing their invaluable contributions
        to the education sector.</p>

    <p>The Aurangabad Education Expo 2023 played a pivotal role in fostering educational growth, facilitating
        collaborations, and celebrating intellectual and cultural diversity. We extend our gratitude to all
        participants, collaborators, and attendees for their invaluable contributions and unwavering support.</p>

    <p>This success paves the way for future endeavors, reinforcing our commitment to advancing education and knowledge
        dissemination.</p>
        <br/>
    <p>Warm Regards, <br/> Basit Siddiqui <br/> Aurangabad Education Expo 2023 <br/> 06 Jan-2024</p>

                    <br />
                  </div>
                </div>
              
            </div>
            <div className="col-lg-1"></div>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Report;
