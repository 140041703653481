import React from 'react';
import Navigation from '../../Components/Navigation'; 
import Footer from '../../Components/Footer';
import {HeroHeader, Loading} from '../../Components/Elements';
var i = 0;
class TermsAndConditions extends React.Component{
  componentDidMount(){
    
    document.title = "Terms & Conditions - Aurangabad Education Expo 2023";
    window.scrollTo(0, 0);
  }
  render(){
    return (
        <div>
      <Navigation />
      {/* Hero Start */}
      <HeroHeader title="Terms &amp; Conditions" />
      {/* Hero End */}
      <div className="about-section about-page section-padding-03">
        <div className="container">
          <h1>Terms &amp; Conditions:</h1>
          <p>Welcome to our website (www.educationexpo.org). These terms and conditions outline the rules and regulations for the use of our website.</p>
          <p>By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use our website if you do not agree with all the terms and conditions stated on this page.</p>
          <p>The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice and any or all Agreements: "Client," "You," and "Your" refers to you, the person accessing this website and accepting the Company's terms and conditions. "The Company," "Ourselves," "We," "Our," and "Us," refers to our Company. "Party," "Parties," or "Us," refers to both the Client and ourselves, or either the Client or ourselves.</p>
          <p>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</p>
          <p>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</p>
          <p>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.</p>
          <p>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</p>
          <p>All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.</p>
          <p>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</p>
          <p>From time to time, this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</p>
          <p>Your use of this website and any dispute arising out of such use of the website is subject to the laws of India.</p>
          <p>We reserve the right to modify these terms and conditions at any time. By using this website, you are agreeing to be bound by the current version of these terms and conditions.</p>
        </div>
        </div>
        <Footer />
        </div>);
  }
}

export default TermsAndConditions;



