import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './Components/Navigation';

import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Downloads from './Pages/Downloads';
import SponsorshipPolicy from './Pages/SponsorshipPolicy';
import SchoolRegistration from './Pages/SchoolRegistration';
import Activities from './Pages/Activities';
import Blogs from './Pages/Blogs';
import BlogDetails from './Pages/BlogDetails';
import Report from './Pages/Report';

import Speakers from './Pages/Speakers';
import SpeakersDetail from './Pages/SpeakersDetail';
import Stalls from './Pages/Stalls';
import StallGallery from './Pages/StallGallery';
import StallTour from './Pages/StallTour';
import Schedules from './Pages/Schedules';
import SchedulesIndoors from './Pages/SchedulesIndoors';
import SchedulesOutdoors from './Pages/SchedulesOutdoors';
import SchedulesDetails from './Pages/SchedulesDetails';
import PressMaterials from './Pages/PressMaterials';
import ActivitiesDetails from './Pages/ActivitiesDetails';
import OrganizingCommittee from './Pages/OrganizingCommittee';
import PressReleases from './Pages/PressReleases';
import PressReleaseDetails from './Pages/PressReleaseDetails';
import StudentRegistration from './Pages/StudentRegistration';
import ActivityRegistration from './Pages/ActivityRegistration';
import ActivityEnrollment from './Pages/ActivityEnrollment'

import TermsAndConditions from './Pages/Others/TermsAndConditions';
import RefundPolicy from './Pages/Others/RefundPolicy';
import PrivacyPolicy from './Pages/Others/PrivacyPolicy';
import ExecutiveReport2022 from './Pages/Others/ExecutiveReport2022';


function App() {

  return (
    <Router>
		  <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/ExecutiveReport2022" element={<ExecutiveReport2022 />} />
        <Route path="/SponsorshipPolicy" element={<SponsorshipPolicy />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Downloads" element={<Downloads />} />
        <Route path="/SchoolRegistration" element={<SchoolRegistration />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/RefundPolicy" element={<RefundPolicy />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Activities" element={<Activities />} />
        <Route path="/Speakers/:slug" element={<SpeakersDetail />} />
        <Route path="/Speakers" element={<Speakers />} />
        <Route path="/Stalls" element={<Stalls />} />
        <Route path="/StallGallery" element={<StallGallery />} />
        <Route path="/StallTour" element={<StallTour />} />
        <Route path="/Schedules" element={<Schedules />} />
        <Route path="/SchedulesIndoors" element={<SchedulesIndoors />} />
        <Route path="/SchedulesOutdoors" element={<SchedulesOutdoors />} />
        <Route path="/Schedules/:slug" element={<SchedulesDetails />} />
        <Route path="/OrganizingCommittee" element={<OrganizingCommittee />} />
        <Route path="/PressReleases" element={<PressReleases />} />
        <Route path="/PressRelease/:slug" element={<PressReleaseDetails />} />
        <Route path="/ActivityDetail" element={<ActivitiesDetails />} />
        <Route path="/ActivityDetail/:slug" element={<ActivitiesDetails />} />
        <Route path="/PressMaterials" element={<PressMaterials />} />
        <Route path="/StudentRegistration/:slug" element={<StudentRegistration />} />
        <Route path="/ActivityRegistration/:slug" element={<ActivityRegistration />} />
        <Route path="/ActivityEnrollment/:slug" element={<ActivityEnrollment />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/Blog" element={<Blogs />} />
        <Route path="/Blog/:slug" element={<BlogDetails />} />
        <Route path="/Report" element={<Report />} />
      </Routes>
    </Router>
  );
}

export default App;
