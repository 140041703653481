import React, { useState, useEffect } from "react";
import { useParams, NavLink, Link } from "react-router-dom";
import { Card } from "../Components/Cards";
import * as config from "../config";
import {
  HeroHeader,
  Loading,
  SmallActivityLabel,
} from "../Components/Elements";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import ErrorCatch from '../Components/ErrorCatch'

const ActivitiesDetails = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [rows, setRows] = useState(null);
  const [loadingAPI, setLoadingAPI] = useState(true);

  const activityFetch = () => {
    setLoadingAPI(true);
    fetch(`${config.API_URL}ActivitiesList`, {
      method: "GET",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => response.text())
      .then((data) => {
        var rows = JSON.parse(data).data;
        setRows(rows);
        setLoadingAPI(false);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  const activitiesFetch = () => {
    fetch(`${config.API_URL}ActivityDetail?slug=` + slug, {
      method: "GET",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => response.text())
      .then((data) => {
        var data = JSON.parse(data).data;
        setData(data);
        document.title = data[0].title;
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };

  useEffect(() => {
    document.title = "Activities - Aurangabad Education Expo 2023";
    if (typeof window.loadMainJS === "function") {
      window.loadMainJS();
    }
    window.scrollTo(0, 0);

    activityFetch();
    activitiesFetch();
  }, [slug]);

  return (
    <div>
      <HeroHeader
        title={
          loadingAPI == true || data == null ? "Loading..." : data[0].title
        }
      />

      <div className="event-single-page section-padding-02">
        <div className="container">
          <div className="row justify-content-between gx-5">
            <div className="col-lg-8">
              {loadingAPI == true ? (
                <Loading msg={`Please wait...`} />
              ) : (
                <div className="single-event">
                  <div className="event-header">
                    <h4 className="event-title">
                      {data == null ? "..." : data[0].title}
                    </h4>
                    <div className="event-thumbnail">
                      <img
                        src={
                          data == null
                            ? "assets/images/blog_img_1.jpg"
                            : data[0].image
                        }
                        alt="Blog Image"
                        className="blog-img"
                      />
                    </div>
                  </div>
                  <div className="event-body">
                    {/* <p className="text">{data == null?"...":data[0].content}</p> */}
                    {data == null ? (
                      "..."
                    ) : (
                      <div
                        className="text"
                        dangerouslySetInnerHTML={{ __html: data[0].content }}
                      />
                    )}
                    <br />
					{data == null ? (
    "Loading..." // or another loading indicator
  ) : (
    <>
      {data[0].closed ? (
        <>
          <Button
            className="btn btn-primary right"
            onClick={() => {
              Swal.fire({
                title: "Registrations Closed!",
                text: `Registrations ${data[0].title} has been Closed! Please stay tuned for more updates`,
                icon: "info",
              });
            }}
          >
            Enroll Now
          </Button>
        </>
      ) : (
        <>
          {data[0].enrollment_fees ? (
            <>
              <p>Enrollment fees: {data[0].enrollment_fees}</p>
              <br />
              <NavLink
                to={`/ActivityEnrollment/${slug}`}
                className="btn btn-primary right"
                replace
              >
                Enroll and Pay Now
              </NavLink>
            </>
          ) : (
            <NavLink
              to={`/ActivityRegistration/${slug}`}
              className="btn btn-primary right"
              replace
            >
              Enroll Now
            </NavLink>
          )}
          <br />
        </>
      )}
    </>
  )}
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-4">
              <div className="exvent-sidebar">
                <div className="sidebar-widget" style={{ margin: "0px" }}>
                  <div
                    className="sidebar-offer"
                    style={{
                      backgroundImage:
                        "url(assets/images/bg/sidebar_ad_bg.jpg)",
                    }}
                  >
                    <h3 className="offer" style={{ fontSize: "27px" }}>
                      Register your School
                    </h3>
                    <h3 style={{ fontSize: "15px", color: "white" }}>
                      Join Us at the Education Expo 2023{" "}
                    </h3>
                    <h5 className="offer-title">
                      Showcase Your Students' Performance
                    </h5>
                    <Link to="/SchoolRegistration" className="btn btn-orange">
                      Register now
                    </Link>
                  </div>
                </div>
                <div className="sidebar-widget" style={{ margin_top: 100 }}>
                  <div className="widget-title">
                    <h4>Popular Posts</h4>
                  </div>
                  <div className="popular-posts">
                    <ul>
                      {rows &&
                        rows.map((item, index) => (
                          <li>
                            <Link
                              className="post-link"
                              to={`/ActivityDetail/${item.slug}`}
                            >
                              <div className="post-thumb">
                                <img src={item.logo} alt />
                              </div>
                              <div className="post-text">
                                <h4 className="title">{item.title}</h4>
                                <span className="post-meta">
                                  <i className="far fa-calendar-alt" />{" "}
                                  {item.activity_date}
                                </span>
                              </div>
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ActivitiesDetails;
