import React, {useState,useEffect} from 'react';
import * as config from '../config';
import {Alert,HeroHeader, Loading} from '../Components/Elements';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import axios from 'axios';
const SchoolRegistration = () =>{
  const [schoolName, setSchoolName] = useState('');
  const [schoolMedium, setSchoolMedium] = useState('');
  const [schoolPattern, setSchoolPattern] = useState('');
  const [schoolPrincipal, setSchoolPrincipal] = useState('');
  const [schoolAddress, setSchoolAddress] = useState('');
  const [schoolEmail, setSchoolEmail] = useState('');
  const [schoolContactNumber, setSchoolContactNumber] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(0);

  useEffect(() => {
    document.title = "School Registration - Aurangabad Education Expo 2023";
    if (typeof window.loadMainJS === 'function') {
      window.loadMainJS();
    }
    // window.scrollTo(0, 0);
  }, []);


  const handleSubmit = (e)=>{
    e.preventDefault();
    const formData = {
      schoolName,
      schoolMedium,
      schoolPattern,
      schoolPrincipal,
      schoolAddress,
      schoolEmail,
      schoolContactNumber,
      contactPerson,
      contactNumber,
      contactEmail
      // Other form field values
    };
    setLoadingStatus(1);
    axios.post(`${config.API_URL}SchoolRegistration`, formData, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
  .then(response => {
    setLoadingStatus(0);
    const data = response.data;
    if (data.success === true) {
      // Reset form fields
      setSchoolName('');
      setSchoolMedium('');
      setSchoolPattern('');
      setSchoolPrincipal('');
      setSchoolAddress('');
      setSchoolEmail('');
      setSchoolContactNumber('');
      setContactPerson('');
      setContactNumber('');
      setContactEmail('');
    }
    setResponseStatus(data.success);
    setResponseMessage(data.message);
  })
  .catch(error => {
    setLoadingStatus(0);
    // Handle any errors
    console.error(error);
    setResponseStatus(false);
    setResponseMessage(error.message);
  });
  };

  return (
      <div>
       {/* <Navigation /> */}
      {/* Hero Start */}
      <HeroHeader title="School Registration" />
      {/* Hero End */}

      {/* Login Start */}
      <div className="login-register-area section-padding-02">
        <div className="container">
          <div className="row justify-content-center g-5">
            <div className="col-lg-6 col-md-8">
              <div className="hero-form mt-0 grey-bg">
                <h3 className="form-title black">School Registration</h3>
                <form action="#" className="exvent-form"  onSubmit={handleSubmit}>
                  <div className="row gy-4">
                    <div className="col-12">
                      <div className="single-input white-bg">
                        <input type="text" placeholder="School Name" value={schoolName} onChange={(e) => setSchoolName(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-input white-bg">
                        <select className='form-control' placeholder="School Medium" value={schoolMedium} onChange={(e) => setSchoolMedium(e.target.value)} >
                          <option value="" selected disabled>Select School Medium</option>
                          <option value="Urdu">Urdu</option>
                          <option value="English">English</option>
                          <option value="Semi English">Semi English</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-input white-bg">
                      <select className='form-control' value={schoolPattern} onChange={(e) => setSchoolPattern(e.target.value)} >
                        <option value="" selected disabled>Select Pattern</option>
                        <option value="State Board">State Board</option>
                        <option value="CBSE">CBSE</option>
                        <option value="ICSE">ICSE</option>
                      </select>
                        {/* <input type="text" placeholder="School Pattern" value={schoolPattern} onChange={(e) => setSchoolPattern(e.target.value)} /> */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-input white-bg">
                        <input type="text" placeholder="School Principal" value={schoolPrincipal} onChange={(e) => setSchoolPrincipal(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-input white-bg">
                        <input type="number" placeholder="Principal Contact Number" value={schoolContactNumber} onChange={(e) => setSchoolContactNumber(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-input white-bg">
                        <input type="email" placeholder="School E-Mail" value={schoolEmail} onChange={(e) => setSchoolEmail(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-input white-bg">
                        <textarea type="text" placeholder="School Address" value={schoolAddress}  onChange={(e) => setSchoolAddress(e.target.value)} ></textarea>
                      </div>
                    </div>


                    <div className="col-12">
                      <div className="single-input white-bg">
                        <input type="text" placeholder="School Coordinator" value={contactPerson} onChange={(e) => setContactPerson(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-input white-bg">
                        <input type="number" placeholder="Coordinator's Number" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-input white-bg">
                        <input type="email" placeholder="Coordinator's E-Mail" value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12">
                      {loadingStatus == 1? (<Loading msg="Please wait..."/>):("") } {responseStatus !== null && (<Alert msg={responseMessage} type={responseStatus == true?"success":"danger"} />)}
                    </div>
                    <div className='col-12' id='resp'></div>
                    <div className="col-sm-5 text-center">
                      <button type="submit" disabled={loadingStatus==1?true:false} className="submit_btn">Registar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Login End */}
      <Footer />
    </div>
  );
}

export default SchoolRegistration;
