import React, { useState, useEffect } from "react";
import * as config from "../config";
import axios from 'axios'


const StallMap = ()=>{
	useEffect(()=>{
		axios.get(config.API_URL+'UnavailableStallList').then(({data})=>{
			data.forEach(e => {


				const element = document.getElementById(e.stall_number);
        if (element) {
          element.classList.add(e.stall_status == "1" ? "sttaken" : "streserved");
        }
			});
		})
	},[])
	return (
	<>
		<div style={{ position: "relative" }}>

	 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 929 696" style={{ position: "absolute", top: -1, left: 0 }} >
	 <g>

	
	 <rect id="1" x="374.19" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M410.1,134.73v35.66h-35.66v-35.66H410.1 M410.6,134.23h-36.66v36.66h36.66V134.23L410.6,134.23z"/> */}
		<rect id="2" x="337.53" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M373.44,134.73v35.66h-35.66v-35.66H373.44 M373.94,134.23h-36.66v36.66h36.66V134.23L373.94,134.23z"/> */}
		<rect id="3" x="300.87" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M336.78,134.73v35.66h-35.66v-35.66H336.78 M337.28,134.23h-36.66v36.66h36.66V134.23L337.28,134.23z"/> */}
		<rect id="4" x="264.21" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M300.12,134.73v35.66h-35.66v-35.66H300.12 M300.62,134.23h-36.66v36.66h36.66V134.23L300.62,134.23z"/> */}
		<rect id="5" x="227.54" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M263.46,134.73v35.66h-35.66v-35.66H263.46 M263.96,134.23h-36.66v36.66h36.66V134.23L263.96,134.23z"/> */}
		<rect id="6" x="190.88" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M226.79,134.73v35.66h-35.66v-35.66H226.79 M227.29,134.23h-36.66v36.66h36.66V134.23L227.29,134.23z"/> */}
		<rect id="7" x="154.22" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M190.13,134.73v35.66h-35.66v-35.66H190.13 M190.63,134.23h-36.66v36.66h36.66V134.23L190.63,134.23z"/> */}
		<rect id="8" x="117.56" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M153.47,134.73v35.66h-35.66v-35.66H153.47 M153.97,134.23h-36.66v36.66h36.66V134.23L153.97,134.23z"/> */}
		<rect id="9" x="80.89" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M116.81,134.73v35.66H81.14v-35.66H116.81 M117.31,134.23H80.64v36.66h36.66V134.23L117.31,134.23z"/> */}
		<rect id="10" x="44.23" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M80.14,134.73v35.66H44.48v-35.66H80.14 M80.64,134.23H43.98v36.66h36.66V134.23L80.64,134.23z"/> */}
		<rect id="11" x="117.56" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M153.47,208.05v35.66h-35.66v-35.66H153.47 M153.97,207.55h-36.66v36.66h36.66V207.55L153.97,207.55z"/> */}
		<rect id="12" x="154.22" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M190.13,208.05v35.66h-35.66v-35.66H190.13 M190.63,207.55h-36.66v36.66h36.66V207.55L190.63,207.55z"/> */}
		<rect id="13" x="190.88" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M226.79,208.05v35.66h-35.66v-35.66H226.79 M227.29,207.55h-36.66v36.66h36.66V207.55L227.29,207.55z"/> */}
		<rect id="14" x="227.54" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M263.46,208.05v35.66h-35.66v-35.66H263.46 M263.96,207.55h-36.66v36.66h36.66V207.55L263.96,207.55z"/> */}
		<rect id="15" x="264.21" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M300.12,208.05v35.66h-35.66v-35.66H300.12 M300.62,207.55h-36.66v36.66h36.66V207.55L300.62,207.55z"/> */}
		<rect id="16" x="300.87" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M336.78,208.05v35.66h-35.66v-35.66H336.78 M337.28,207.55h-36.66v36.66h36.66V207.55L337.28,207.55z"/> */}
		<rect id="17" x="337.53" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M373.44,208.05v35.66h-35.66v-35.66H373.44 M373.94,207.55h-36.66v36.66h36.66V207.55L373.94,207.55z"/> */}
		<rect id="18" x="374.19" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M410.1,208.05v35.66h-35.66v-35.66H410.1 M410.6,207.55h-36.66v36.66h36.66V207.55L410.6,207.55z"/> */}
		<rect id="19" x="374.19" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M410.1,244.71v35.66h-35.66v-35.66H410.1 M410.6,244.21h-36.66v36.66h36.66V244.21L410.6,244.21z"/> */}
		<rect id="20" x="337.53" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M373.44,244.71v35.66h-35.66v-35.66H373.44 M373.94,244.21h-36.66v36.66h36.66V244.21L373.94,244.21z"/> */}
		<rect id="21" x="300.87" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M336.78,244.71v35.66h-35.66v-35.66H336.78 M337.28,244.21h-36.66v36.66h36.66V244.21L337.28,244.21z"/> */}
		<rect id="22" x="264.21" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M300.12,244.71v35.66h-35.66v-35.66H300.12 M300.62,244.21h-36.66v36.66h36.66V244.21L300.62,244.21z"/> */}
		<rect id="23" x="227.54" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M263.46,244.71v35.66h-35.66v-35.66H263.46 M263.96,244.21h-36.66v36.66h36.66V244.21L263.96,244.21z"/> */}
		<rect id="24" x="190.88" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M226.79,244.71v35.66h-35.66v-35.66H226.79 M227.29,244.21h-36.66v36.66h36.66V244.21L227.29,244.21z"/> */}
		<rect id="25" x="154.22" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M190.13,244.71v35.66h-35.66v-35.66H190.13 M190.63,244.21h-36.66v36.66h36.66V244.21L190.63,244.21z"/> */}
		<rect id="26" x="117.56" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M153.47,244.71v35.66h-35.66v-35.66H153.47 M153.97,244.21h-36.66v36.66h36.66V244.21L153.97,244.21z"/> */}
		<rect id="27" x="44.23" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M80.14,318.04v35.66H44.48v-35.66H80.14 M80.64,317.54H43.98v36.66h36.66V317.54L80.64,317.54z"/> */}
		<rect id="28" x="80.89" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M116.81,318.04v35.66H81.14v-35.66H116.81 M117.31,317.54H80.64v36.66h36.66V317.54L117.31,317.54z"/> */}
		<rect id="29" x="117.56" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M153.47,318.04v35.66h-35.66v-35.66H153.47 M153.97,317.54h-36.66v36.66h36.66V317.54L153.97,317.54z"/> */}
		<rect id="30" x="154.22" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M190.13,318.04v35.66h-35.66v-35.66H190.13 M190.63,317.54h-36.66v36.66h36.66V317.54L190.63,317.54z"/> */}
		<rect id="31" x="190.88" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M226.79,318.04v35.66h-35.66v-35.66H226.79 M227.29,317.54h-36.66v36.66h36.66V317.54L227.29,317.54z"/> */}
		<rect id="32" x="227.54" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M263.46,318.04v35.66h-35.66v-35.66H263.46 M263.96,317.54h-36.66v36.66h36.66V317.54L263.96,317.54z"/> */}
		<rect id="33" x="264.21" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M300.12,318.04v35.66h-35.66v-35.66H300.12 M300.62,317.54h-36.66v36.66h36.66V317.54L300.62,317.54z"/> */}
		<rect id="34" x="300.87" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M336.78,318.04v35.66h-35.66v-35.66H336.78 M337.28,317.54h-36.66v36.66h36.66V317.54L337.28,317.54z"/> */}
		<rect id="35" x="374.19" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M410.1,318.04v35.66h-35.66v-35.66H410.1 M410.6,317.54h-36.66v36.66h36.66V317.54L410.6,317.54z"/> */}
		<rect id="36" x="374.19" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M410.1,354.7v35.66h-35.66V354.7H410.1 M410.6,354.2h-36.66v36.66h36.66V354.2L410.6,354.2z"/> */}
		<rect id="37" x="300.87" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M336.78,354.7v35.66h-35.66V354.7H336.78 M337.28,354.2h-36.66v36.66h36.66V354.2L337.28,354.2z"/> */}
		<rect id="38" x="264.21" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M300.12,354.7v35.66h-35.66V354.7H300.12 M300.62,354.2h-36.66v36.66h36.66V354.2L300.62,354.2z"/> */}
		<rect id="39" x="227.54" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M263.46,354.7v35.66h-35.66V354.7H263.46 M263.96,354.2h-36.66v36.66h36.66V354.2L263.96,354.2z"/> */}
		<rect id="40" x="190.88" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M226.79,354.7v35.66h-35.66V354.7H226.79 M227.29,354.2h-36.66v36.66h36.66V354.2L227.29,354.2z"/> */}
		<rect id="41" x="154.22" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M190.13,354.7v35.66h-35.66V354.7H190.13 M190.63,354.2h-36.66v36.66h36.66V354.2L190.63,354.2z"/> */}
		<rect id="42" x="117.56" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M153.47,354.7v35.66h-35.66V354.7H153.47 M153.97,354.2h-36.66v36.66h36.66V354.2L153.97,354.2z"/> */}
		<rect id="43" x="80.89" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M116.81,354.7v35.66H81.14V354.7H116.81 M117.31,354.2H80.64v36.66h36.66V354.2L117.31,354.2z"/> */}
		<rect id="44" x="44.23" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M80.14,354.7v35.66H44.48V354.7H80.14 M80.64,354.2H43.98v36.66h36.66V354.2L80.64,354.2z"/> */}
		<rect id="45" x="44.23" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M80.14,428.02v35.66H44.48v-35.66H80.14 M80.64,427.52H43.98v36.66h36.66V427.52L80.64,427.52z"/> */}
		<rect id="46" x="117.56" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M153.47,428.02v35.66h-35.66v-35.66H153.47 M153.97,427.52h-36.66v36.66h36.66V427.52L153.97,427.52z"/> */}
		<rect id="47" x="154.22" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M190.13,428.02v35.66h-35.66v-35.66H190.13 M190.63,427.52h-36.66v36.66h36.66V427.52L190.63,427.52z"/> */}
		<rect id="48" x="190.88" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M226.79,428.02v35.66h-35.66v-35.66H226.79 M227.29,427.52h-36.66v36.66h36.66V427.52L227.29,427.52z"/> */}
		<rect id="49" x="227.54" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M263.46,428.02v35.66h-35.66v-35.66H263.46 M263.96,427.52h-36.66v36.66h36.66V427.52L263.96,427.52z"/> */}
		<rect id="50" x="264.21" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M300.12,428.02v35.66h-35.66v-35.66H300.12 M300.62,427.52h-36.66v36.66h36.66V427.52L300.62,427.52z"/> */}
		<rect id="51" x="300.87" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M336.78,428.02v35.66h-35.66v-35.66H336.78 M337.28,427.52h-36.66v36.66h36.66V427.52L337.28,427.52z"/> */}
		<rect id="52" x="337.53" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M373.44,428.02v35.66h-35.66v-35.66H373.44 M373.94,427.52h-36.66v36.66h36.66V427.52L373.94,427.52z"/> */}
		<rect id="53" x="374.19" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M410.1,428.02v35.66h-35.66v-35.66H410.1 M410.6,427.52h-36.66v36.66h36.66V427.52L410.6,427.52z"/> */}
		<rect id="54" x="374.19" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M410.1,464.69v35.66h-35.66v-35.66H410.1 M410.6,464.19h-36.66v36.66h36.66V464.19L410.6,464.19z"/> */}
		<rect id="55" x="337.53" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M373.44,464.69v35.66h-35.66v-35.66H373.44 M373.94,464.19h-36.66v36.66h36.66V464.19L373.94,464.19z"/> */}
		<rect id="56" x="300.87" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M336.78,464.69v35.66h-35.66v-35.66H336.78 M337.28,464.19h-36.66v36.66h36.66V464.19L337.28,464.19z"/> */}
		<rect id="57" x="264.21" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M300.12,464.69v35.66h-35.66v-35.66H300.12 M300.62,464.19h-36.66v36.66h36.66V464.19L300.62,464.19z"/> */}
		<rect id="58" x="227.54" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M263.46,464.69v35.66h-35.66v-35.66H263.46 M263.96,464.19h-36.66v36.66h36.66V464.19L263.96,464.19z"/> */}
		<rect id="59" x="190.88" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M226.79,464.69v35.66h-35.66v-35.66H226.79 M227.29,464.19h-36.66v36.66h36.66V464.19L227.29,464.19z"/> */}
		<rect id="60" x="154.22" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M190.13,464.69v35.66h-35.66v-35.66H190.13 M190.63,464.19h-36.66v36.66h36.66V464.19L190.63,464.19z"/> */}
		<rect id="61" x="117.56" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M153.47,464.69v35.66h-35.66v-35.66H153.47 M153.97,464.19h-36.66v36.66h36.66V464.19L153.97,464.19z"/> */}
		<rect id="62" x="44.23" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M80.14,464.69v35.66H44.48v-35.66H80.14 M80.64,464.19H43.98v36.66h36.66V464.19L80.64,464.19z"/> */}
		<rect id="63" x="44.23" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M80.14,538.01v35.66H44.48v-35.66H80.14 M80.64,537.51H43.98v36.66h36.66V537.51L80.64,537.51z"/> */}
		<rect id="64" x="80.89" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M116.81,538.01v35.66H81.14v-35.66H116.81 M117.31,537.51H80.64v36.66h36.66V537.51L117.31,537.51z"/> */}
		<rect id="65" x="117.56" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M153.47,538.01v35.66h-35.66v-35.66H153.47 M153.97,537.51h-36.66v36.66h36.66V537.51L153.97,537.51z"/> */}
		<rect id="66" x="154.22" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M190.13,538.01v35.66h-35.66v-35.66H190.13 M190.63,537.51h-36.66v36.66h36.66V537.51L190.63,537.51z"/> */}
		<rect id="67" x="190.88" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M226.79,538.01v35.66h-35.66v-35.66H226.79 M227.29,537.51h-36.66v36.66h36.66V537.51L227.29,537.51z"/> */}
		<rect id="68" x="227.54" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M263.46,538.01v35.66h-35.66v-35.66H263.46 M263.96,537.51h-36.66v36.66h36.66V537.51L263.96,537.51z"/> */}
		<rect id="69" x="264.21" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M300.12,538.01v35.66h-35.66v-35.66H300.12 M300.62,537.51h-36.66v36.66h36.66V537.51L300.62,537.51z"/> */}
		<rect id="70" x="300.87" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M336.78,538.01v35.66h-35.66v-35.66H336.78 M337.28,537.51h-36.66v36.66h36.66V537.51L337.28,537.51z"/> */}
		<rect id="71" x="337.53" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M373.44,538.01v35.66h-35.66v-35.66H373.44 M373.94,537.51h-36.66v36.66h36.66V537.51L373.94,537.51z"/> */}
		<rect id="72" x="374.19" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M410.1,538.01v35.66h-35.66v-35.66H410.1 M410.6,537.51h-36.66v36.66h36.66V537.51L410.6,537.51z"/> */}
		<rect id="73" x="374.19" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M410.1,574.67v35.66h-35.66v-35.66H410.1 M410.6,574.17h-36.66v36.66h36.66V574.17L410.6,574.17z"/> */}
		<rect id="74" x="337.53" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M373.44,574.67v35.66h-35.66v-35.66H373.44 M373.94,574.17h-36.66v36.66h36.66V574.17L373.94,574.17z"/> */}
		<rect id="75" x="300.87" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M336.78,574.67v35.66h-35.66v-35.66H336.78 M337.28,574.17h-36.66v36.66h36.66V574.17L337.28,574.17z"/> */}
		<rect id="76" x="264.21" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M300.12,574.67v35.66h-35.66v-35.66H300.12 M300.62,574.17h-36.66v36.66h36.66V574.17L300.62,574.17z"/> */}
		<rect id="77" x="227.54" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M263.46,574.67v35.66h-35.66v-35.66H263.46 M263.96,574.17h-36.66v36.66h36.66V574.17L263.96,574.17z"/> */}
		<rect id="78" x="190.88" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M226.79,574.67v35.66h-35.66v-35.66H226.79 M227.29,574.17h-36.66v36.66h36.66V574.17L227.29,574.17z"/> */}
		<rect id="79" x="154.22" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M190.13,574.67v35.66h-35.66v-35.66H190.13 M190.63,574.17h-36.66v36.66h36.66V574.17L190.63,574.17z"/> */}
		<rect id="80" x="117.56" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M153.47,574.67v35.66h-35.66v-35.66H153.47 M153.97,574.17h-36.66v36.66h36.66V574.17L153.97,574.17z"/> */}
		<rect id="81" x="80.89" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M116.81,574.67v35.66H81.14v-35.66H116.81 M117.31,574.17H80.64v36.66h36.66V574.17L117.31,574.17z"/> */}
		<rect id="82" x="44.23" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M80.14,574.67v35.66H44.48v-35.66H80.14 M80.64,574.17H43.98v36.66h36.66V574.17L80.64,574.17z"/> */}
		<rect id="83" x="520.84" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M556.75,134.73v35.66h-35.66v-35.66H556.75 M557.25,134.23h-36.66v36.66h36.66V134.23L557.25,134.23z"/> */}
		<rect id="84" x="557.5" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M593.42,134.73v35.66h-35.66v-35.66H593.42 M593.92,134.23h-36.66v36.66h36.66V134.23L593.92,134.23z"/> */}
		<rect id="85" x="594.17" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M630.08,134.73v35.66h-35.66v-35.66H630.08 M630.58,134.23h-36.66v36.66h36.66V134.23L630.58,134.23z"/> */}
		<rect id="86" x="630.83" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M666.74,134.73v35.66h-35.66v-35.66H666.74 M667.24,134.23h-36.66v36.66h36.66V134.23L667.24,134.23z"/> */}
		<rect id="87" x="667.49" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M703.4,134.73v35.66h-35.66v-35.66H703.4 M703.9,134.23h-36.66v36.66h36.66V134.23L703.9,134.23z"/> */}
		<rect id="88" x="704.15" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M740.07,134.73v35.66H704.4v-35.66H740.07 M740.57,134.23H703.9v36.66h36.66V134.23L740.57,134.23z"/> */}
		<rect id="89" x="740.82" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M776.73,134.73v35.66h-35.66v-35.66H776.73 M777.23,134.23h-36.66v36.66h36.66V134.23L777.23,134.23z"/> */}
		<rect id="90" x="777.48" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M813.39,134.73v35.66h-35.66v-35.66H813.39 M813.89,134.23h-36.66v36.66h36.66V134.23L813.89,134.23z"/> */}
		<rect id="91" x="814.14" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M850.05,134.73v35.66h-35.66v-35.66H850.05 M850.55,134.23h-36.66v36.66h36.66V134.23L850.55,134.23z"/> */}
		<rect id="92" x="850.8" y="134.48" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M886.71,134.73v35.66h-35.66v-35.66H886.71 M887.21,134.23h-36.66v36.66h36.66V134.23L887.21,134.23z"/> */}
		<rect id="93" x="777.48" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M813.39,208.05v35.66h-35.66v-35.66H813.39 M813.89,207.55h-36.66v36.66h36.66V207.55L813.89,207.55z"/> */}
		<rect id="94" x="740.82" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M776.73,208.05v35.66h-35.66v-35.66H776.73 M777.23,207.55h-36.66v36.66h36.66V207.55L777.23,207.55z"/> */}
		<rect id="95" x="704.15" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M740.07,208.05v35.66H704.4v-35.66H740.07 M740.57,207.55H703.9v36.66h36.66V207.55L740.57,207.55z"/> */}
		<rect id="96" x="667.49" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M703.4,208.05v35.66h-35.66v-35.66H703.4 M703.9,207.55h-36.66v36.66h36.66V207.55L703.9,207.55z"/> */}
		<rect id="97" x="630.83" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M666.74,208.05v35.66h-35.66v-35.66H666.74 M667.24,207.55h-36.66v36.66h36.66V207.55L667.24,207.55z"/> */}
		<rect id="98" x="594.17" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M630.08,208.05v35.66h-35.66v-35.66H630.08 M630.58,207.55h-36.66v36.66h36.66V207.55L630.58,207.55z"/> */}
		<rect id="99" x="557.5" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M593.42,208.05v35.66h-35.66v-35.66H593.42 M593.92,207.55h-36.66v36.66h36.66V207.55L593.92,207.55z"/> */}
		<rect id="100" x="520.84" y="207.8" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M556.75,208.05v35.66h-35.66v-35.66H556.75 M557.25,207.55h-36.66v36.66h36.66V207.55L557.25,207.55z"/> */}
		<rect id="101" x="520.84" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M556.75,244.71v35.66h-35.66v-35.66H556.75 M557.25,244.21h-36.66v36.66h36.66V244.21L557.25,244.21z"/> */}
		<rect id="102" x="557.5" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M593.42,244.71v35.66h-35.66v-35.66H593.42 M593.92,244.21h-36.66v36.66h36.66V244.21L593.92,244.21z"/> */}
		<rect id="103" x="594.17" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M630.08,244.71v35.66h-35.66v-35.66H630.08 M630.58,244.21h-36.66v36.66h36.66V244.21L630.58,244.21z"/> */}
		<rect id="104" x="630.83" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M666.74,244.71v35.66h-35.66v-35.66H666.74 M667.24,244.21h-36.66v36.66h36.66V244.21L667.24,244.21z"/> */}
		<rect id="105" x="667.49" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M703.4,244.71v35.66h-35.66v-35.66H703.4 M703.9,244.21h-36.66v36.66h36.66V244.21L703.9,244.21z"/> */}
		<rect id="106" x="704.15" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M740.07,244.71v35.66H704.4v-35.66H740.07 M740.57,244.21H703.9v36.66h36.66V244.21L740.57,244.21z"/> */}
		<rect id="107" x="740.82" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M776.73,244.71v35.66h-35.66v-35.66H776.73 M777.23,244.21h-36.66v36.66h36.66V244.21L777.23,244.21z"/> */}
		<rect id="108" x="777.48" y="244.46" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M813.39,244.71v35.66h-35.66v-35.66H813.39 M813.89,244.21h-36.66v36.66h36.66V244.21L813.89,244.21z"/> */}
		<rect id="109" x="850.8" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M886.71,318.04v35.66h-35.66v-35.66H886.71 M887.21,317.54h-36.66v36.66h36.66V317.54L887.21,317.54z"/> */}
		<rect id="110" x="814.14" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M850.05,318.04v35.66h-35.66v-35.66H850.05 M850.55,317.54h-36.66v36.66h36.66V317.54L850.55,317.54z"/> */}
		<rect id="111" x="777.48" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M813.39,318.04v35.66h-35.66v-35.66H813.39 M813.89,317.54h-36.66v36.66h36.66V317.54L813.89,317.54z"/> */}
		<rect id="112" x="740.82" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M776.73,318.04v35.66h-35.66v-35.66H776.73 M777.23,317.54h-36.66v36.66h36.66V317.54L777.23,317.54z"/> */}
		<rect id="113" x="704.15" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M740.07,318.04v35.66H704.4v-35.66H740.07 M740.57,317.54H703.9v36.66h36.66V317.54L740.57,317.54z"/> */}
		<rect id="114" x="667.49" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M703.4,318.04v35.66h-35.66v-35.66H703.4 M703.9,317.54h-36.66v36.66h36.66V317.54L703.9,317.54z"/> */}
		<rect id="115" x="630.83" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M666.74,318.04v35.66h-35.66v-35.66H666.74 M667.24,317.54h-36.66v36.66h36.66V317.54L667.24,317.54z"/> */}
		<rect id="116" x="594.17" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M630.08,318.04v35.66h-35.66v-35.66H630.08 M630.58,317.54h-36.66v36.66h36.66V317.54L630.58,317.54z"/> */}
		<rect id="117" x="520.84" y="317.79" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M556.75,318.04v35.66h-35.66v-35.66H556.75 M557.25,317.54h-36.66v36.66h36.66V317.54L557.25,317.54z"/> */}
		<rect id="118" x="520.84" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M556.75,354.7v35.66h-35.66V354.7H556.75 M557.25,354.2h-36.66v36.66h36.66V354.2L557.25,354.2z"/> */}
		<rect id="119" x="594.17" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M630.08,354.7v35.66h-35.66V354.7H630.08 M630.58,354.2h-36.66v36.66h36.66V354.2L630.58,354.2z"/> */}
		<rect id="120" x="630.83" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M666.74,354.7v35.66h-35.66V354.7H666.74 M667.24,354.2h-36.66v36.66h36.66V354.2L667.24,354.2z"/> */}
		<rect id="121" x="667.49" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M703.4,354.7v35.66h-35.66V354.7H703.4 M703.9,354.2h-36.66v36.66h36.66V354.2L703.9,354.2z"/> */}
		<rect id="122" x="704.15" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M740.07,354.7v35.66H704.4V354.7H740.07 M740.57,354.2H703.9v36.66h36.66V354.2L740.57,354.2z"/> */}
		<rect id="123" x="740.82" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M776.73,354.7v35.66h-35.66V354.7H776.73 M777.23,354.2h-36.66v36.66h36.66V354.2L777.23,354.2z"/> */}
		<rect id="124" x="777.48" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M813.39,354.7v35.66h-35.66V354.7H813.39 M813.89,354.2h-36.66v36.66h36.66V354.2L813.89,354.2z"/> */}
		<rect id="125" x="814.14" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M850.05,354.7v35.66h-35.66V354.7H850.05 M850.55,354.2h-36.66v36.66h36.66V354.2L850.55,354.2z"/> */}
		<rect id="126" x="850.8" y="354.45" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M886.71,354.7v35.66h-35.66V354.7H886.71 M887.21,354.2h-36.66v36.66h36.66V354.2L887.21,354.2z"/> */}
		<rect id="127" x="850.8" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M886.71,428.02v35.66h-35.66v-35.66H886.71 M887.21,427.52h-36.66v36.66h36.66V427.52L887.21,427.52z"/> */}
		<rect id="128" x="777.48" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M813.39,428.02v35.66h-35.66v-35.66H813.39 M813.89,427.52h-36.66v36.66h36.66V427.52L813.89,427.52z"/> */}
		<rect id="129" x="740.82" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M776.73,428.02v35.66h-35.66v-35.66H776.73 M777.23,427.52h-36.66v36.66h36.66V427.52L777.23,427.52z"/> */}
		<rect id="130" x="704.15" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M740.07,428.02v35.66H704.4v-35.66H740.07 M740.57,427.52H703.9v36.66h36.66V427.52L740.57,427.52z"/> */}
		<rect id="131" x="667.49" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M703.4,428.02v35.66h-35.66v-35.66H703.4 M703.9,427.52h-36.66v36.66h36.66V427.52L703.9,427.52z"/> */}
		<rect id="132" x="630.83" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M666.74,428.02v35.66h-35.66v-35.66H666.74 M667.24,427.52h-36.66v36.66h36.66V427.52L667.24,427.52z"/> */}
		<rect id="133" x="594.17" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M630.08,428.02v35.66h-35.66v-35.66H630.08 M630.58,427.52h-36.66v36.66h36.66V427.52L630.58,427.52z"/> */}
		<rect id="134" x="557.5" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M593.42,428.02v35.66h-35.66v-35.66H593.42 M593.92,427.52h-36.66v36.66h36.66V427.52L593.92,427.52z"/> */}
		<rect id="135" x="520.84" y="427.78" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M556.75,428.02v35.66h-35.66v-35.66H556.75 M557.25,427.52h-36.66v36.66h36.66V427.52L557.25,427.52z"/> */}
		<rect id="136" x="520.84" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M556.75,464.69v35.66h-35.66v-35.66H556.75 M557.25,464.19h-36.66v36.66h36.66V464.19L557.25,464.19z"/> */}
		<rect id="137" x="557.5" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M593.42,464.69v35.66h-35.66v-35.66H593.42 M593.92,464.19h-36.66v36.66h36.66V464.19L593.92,464.19z"/> */}
		<rect id="138" x="594.17" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M630.08,464.69v35.66h-35.66v-35.66H630.08 M630.58,464.19h-36.66v36.66h36.66V464.19L630.58,464.19z"/> */}
		<rect id="139" x="630.83" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M666.74,464.69v35.66h-35.66v-35.66H666.74 M667.24,464.19h-36.66v36.66h36.66V464.19L667.24,464.19z"/> */}
		<rect id="140" x="667.49" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M703.4,464.69v35.66h-35.66v-35.66H703.4 M703.9,464.19h-36.66v36.66h36.66V464.19L703.9,464.19z"/> */}
		<rect id="141" x="704.15" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M740.07,464.69v35.66H704.4v-35.66H740.07 M740.57,464.19H703.9v36.66h36.66V464.19L740.57,464.19z"/> */}
		<rect id="142" x="740.82" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M776.73,464.69v35.66h-35.66v-35.66H776.73 M777.23,464.19h-36.66v36.66h36.66V464.19L777.23,464.19z"/> */}
		<rect id="143" x="777.48" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M813.39,464.69v35.66h-35.66v-35.66H813.39 M813.89,464.19h-36.66v36.66h36.66V464.19L813.89,464.19z"/> */}
		<rect id="144" x="850.8" y="464.44" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M886.71,464.69v35.66h-35.66v-35.66H886.71 M887.21,464.19h-36.66v36.66h36.66V464.19L887.21,464.19z"/> */}
		<rect id="145" x="850.8" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M886.71,538.01v35.66h-35.66v-35.66H886.71 M887.21,537.51h-36.66v36.66h36.66V537.51L887.21,537.51z"/> */}
		<rect id="146" x="814.14" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M850.05,538.01v35.66h-35.66v-35.66H850.05 M850.55,537.51h-36.66v36.66h36.66V537.51L850.55,537.51z"/> */}
		<rect id="147" x="777.48" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M813.39,538.01v35.66h-35.66v-35.66H813.39 M813.89,537.51h-36.66v36.66h36.66V537.51L813.89,537.51z"/> */}
		<rect id="148" x="740.82" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M776.73,538.01v35.66h-35.66v-35.66H776.73 M777.23,537.51h-36.66v36.66h36.66V537.51L777.23,537.51z"/> */}
		<rect id="149" x="704.15" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M740.07,538.01v35.66H704.4v-35.66H740.07 M740.57,537.51H703.9v36.66h36.66V537.51L740.57,537.51z"/> */}
		<rect id="150" x="667.49" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M703.4,538.01v35.66h-35.66v-35.66H703.4 M703.9,537.51h-36.66v36.66h36.66V537.51L703.9,537.51z"/> */}
		<rect id="151" x="630.83" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M666.74,538.01v35.66h-35.66v-35.66H666.74 M667.24,537.51h-36.66v36.66h36.66V537.51L667.24,537.51z"/> */}
		<rect id="152" x="594.17" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M630.08,538.01v35.66h-35.66v-35.66H630.08 M630.58,537.51h-36.66v36.66h36.66V537.51L630.58,537.51z"/> */}
		<rect id="153" x="557.5" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M593.42,538.01v35.66h-35.66v-35.66H593.42 M593.92,537.51h-36.66v36.66h36.66V537.51L593.92,537.51z"/> */}
		<rect id="154" x="520.84" y="537.76" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M556.75,538.01v35.66h-35.66v-35.66H556.75 M557.25,537.51h-36.66v36.66h36.66V537.51L557.25,537.51z"/> */}
		<rect id="155" x="520.84" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M556.75,574.67v35.66h-35.66v-35.66H556.75 M557.25,574.17h-36.66v36.66h36.66V574.17L557.25,574.17z"/> */}
		<rect id="156" x="557.5" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M593.42,574.67v35.66h-35.66v-35.66H593.42 M593.92,574.17h-36.66v36.66h36.66V574.17L593.92,574.17z"/> */}
		<rect id="157" x="594.17" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M630.08,574.67v35.66h-35.66v-35.66H630.08 M630.58,574.17h-36.66v36.66h36.66V574.17L630.58,574.17z"/> */}
		<rect id="158" x="630.83" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M666.74,574.67v35.66h-35.66v-35.66H666.74 M667.24,574.17h-36.66v36.66h36.66V574.17L667.24,574.17z"/> */}
		<rect id="159" x="667.49" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M703.4,574.67v35.66h-35.66v-35.66H703.4 M703.9,574.17h-36.66v36.66h36.66V574.17L703.9,574.17z"/> */}
		<rect id="160" x="704.15" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M740.07,574.67v35.66H704.4v-35.66H740.07 M740.57,574.17H703.9v36.66h36.66V574.17L740.57,574.17z"/> */}
		<rect id="161" x="740.82" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M776.73,574.67v35.66h-35.66v-35.66H776.73 M777.23,574.17h-36.66v36.66h36.66V574.17L777.23,574.17z"/> */}
		<rect id="162" x="777.48" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M813.39,574.67v35.66h-35.66v-35.66H813.39 M813.89,574.17h-36.66v36.66h36.66V574.17L813.89,574.17z"/> */}
		<rect id="163" x="814.14" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M850.05,574.67v35.66h-35.66v-35.66H850.05 M850.55,574.17h-36.66v36.66h36.66V574.17L850.55,574.17z"/> */}
		<rect id="164" x="850.8" y="574.42" fill="none" width="36.16" height="36.16"/>
		{/* <path d="M886.71,574.67v35.66h-35.66v-35.66H886.71 M887.21,574.17h-36.66v36.66h36.66V574.17L887.21,574.17z"/> */}
	</g>
</svg>

<img style={{ width: "100%", position: "relative", zIndex: "2"}} src={ "../assets/images/2023/stalls_map.png"} alt="Stalls Map" />

        </div>
	</>
	)
}

export default StallMap;
