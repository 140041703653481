import React, { useState, useEffect } from "react";
import * as config from "../config";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
const Schedules = () => {
    const [rows, setRows] = useState([]);
    useEffect(() => {
        document.title = "Schedules - Aurangabad Education Expo 2023";
        if (typeof window.loadMainJS === "function") {
            window.loadMainJS();
        }
        // window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetch(`${config.API_URL}ScheduleActivityList`, {
            method: "GET",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
            .then((response) => response.text())
            .then((data) => {
                var rows = JSON.parse(data).data;
                console.log(rows);
                setRows(rows);
            })
            .catch((error) => {
                // Handle any errors
                console.error(error);
            });
    }, []);


    const ScheduleItems = (day) => {
        return rows
            .filter((item) => item.schedule_id === day)
            .map((item, index) => (
                <div key={index} className="row g-0 align-items-center shedule-row">
                    <div className="col-lg-3 col-12">
                        <div className="schedule-thumbnail">
                            <div className="schedule-time">
                                <h3 className="time">{14+day}</h3>
                                <div className="month-time">
                                    <span className="month">December</span>
                                    <span className="duration-event">{item.schedule_activity_timing}</span>
                                </div>
                            </div>
                            <img style={{ width: "90%" }} src={item.schedule_activity_image} alt="Thumbnail" className="schedule-thumb-img" />
                        </div>
                    </div>
                    <div className="col-lg-9 col-12">
                        <div className="schedule-content-wrap">
                            <div className="schedule-content">
                                <div className="schedule-location">
                                    <p><span><i className="fas fa-map-marker-alt" /> </span>{item.location} Auditorium</p>
                                </div>
                                <Link to={item.slug}><h4 className="schedule-title">{item.schedule_activity_title} </h4></Link>
                                <div className="schedule-descrip">
                                <p
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html:  item.schedule_activity_short_description
                        
                    }}
                  />
                                    {/* <p>{item.schedule_activity_short_description}</p> */}
                                    {/* <p className="collapse " id={"collapseContent" + item.schedule_activity_id}>{item.schedule_activity_description}</p> */}
                                </div>
                            </div>
                            {/* <a className="minus-btn" data-bs-toggle="collapse" href={"#collapseContent" + item.schedule_activity_id} role="button" aria-expanded="false" aria-controls={"collapseContent" + item.schedule_activity_id}>
                                <div className="plus-icon-2">
                                    <div className="plus-icon-shape" />
                                </div>
                            </a> */}
                        </div>
                    </div>
                </div>
            ));
    };

    return (
        <div>

            <div className="section exvent-hero-section d-lg-flex d-block align-items-center inner-page-hero" style={{ backgroundImage: "url(assets/images/2022/02.jpeg)" }}>
                <img className="shape-1 img-fluid" src="assets/images/shape/hero_shape1.png" alt="" />
                <img className="shape-2 img-fluid" src="assets/images/shape/hero_shape2.png" alt="" />
                <div className="container">
                    <div className="row exvent-hero-row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <div className="page-title">
                                <h2 className="section-title">Event Schedule</h2>
                            </div>
                            <div className="page-navigation">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="/">Event Schedule</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="schedule-area section-padding-02 grey-bg">

                <img src="assets/images/shape/schedule_shape1.png" className="schedule-shape1" alt="Shape1" />
                <img src="assets/images/shape/schedule_shape2.png" className="schedule-shape2" alt="Shape2" />
                <div className="container">
                   
                    <div className="row g-0 align-items-start">
                        <div className="col-2 sticky-col">
                            <div className="schedule-side-nav">
                                <div className="nav d-block nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <p className="nav-link active" id="day-1-tab" data-bs-toggle="pill" data-bs-target="#v-day-1-tab" role="tab" aria-controls="v-day-1-tab" aria-selected="true">Day-1</p>
                                    <p className="nav-link" id="day-2-tab" data-bs-toggle="pill" data-bs-target="#v-day-2-tab" role="tab" aria-controls="v-day-2-tab" aria-selected="false">Day-2</p>
                                    <p className="nav-link" id="day-3-tab" data-bs-toggle="pill" data-bs-target="#v-day-3-tab" role="tab" aria-controls="v-day-3-tab" aria-selected="false">Day-3</p>
                                    <p className="nav-link" id="day-4-tab" data-bs-toggle="pill" data-bs-target="#v-day-4-tab" role="tab" aria-controls="v-day-4-tab" aria-selected="false">Day-4</p>
                                    <p className="nav-link" id="day-5-tab" data-bs-toggle="pill" data-bs-target="#v-day-5-tab" role="tab" aria-controls="v-day-5-tab" aria-selected="false">Day-5</p>
                                    <p className="nav-link" id="day-6-tab" data-bs-toggle="pill" data-bs-target="#v-day-6-tab" role="tab" aria-controls="v-day-6-tab" aria-selected="false">Day-6</p>
                                    <p className="nav-link" id="day-7-tab" data-bs-toggle="pill" data-bs-target="#v-day-7-tab" role="tab" aria-controls="v-day-7-tab" aria-selected="false">Day-7</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-10">
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane show active" id="v-day-1-tab" role="tabpanel">
                                    <div className="schedule-wrapper">
                                        <div className="single-schedule">
                                            {ScheduleItems(1)}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="v-day-2-tab" role="tabpanel">
                                    <div className="schedule-wrapper">
                                        <div className="single-schedule">
                                            {ScheduleItems(2)}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="v-day-3-tab" role="tabpanel">
                                    <div className="schedule-wrapper">
                                        <div className="single-schedule">
                                            {ScheduleItems(3)}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="v-day-4-tab" role="tabpanel">
                                    <div className="schedule-wrapper">
                                        <div className="single-schedule">
                                            {ScheduleItems(4)}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="v-day-5-tab" role="tabpanel">
                                    <div className="schedule-wrapper">
                                        <div className="single-schedule">
                                            {ScheduleItems(5)}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="v-day-6-tab" role="tabpanel">
                                    <div className="schedule-wrapper">
                                        <div className="single-schedule">
                                            {ScheduleItems(6)}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="v-day-7-tab" role="tabpanel">
                                    <div className="schedule-wrapper">
                                        <div className="single-schedule">
                                            {ScheduleItems(7)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    );
};

export default Schedules;
