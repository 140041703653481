import React from 'react';
import Navigation from '../../Components/Navigation'; 
import Footer from '../../Components/Footer';
import {HeroHeader, Loading} from '../../Components/Elements';
const ExecutiveReport2022 = () => {
    document.title = "Aurangabad Education Expo 2022 Executive Report";
    return (
        <div>
            <Navigation />
            {/* Hero Start */}
            <HeroHeader title="EduExpo 2022 Executive Report" />
            {/* Hero End */}
            <div className="about-section about-page section-padding-03">
                <div className="container">
                <p>The <b>"Aurangabad Education Expo-2022"</b> was successfully held over 7-days during <b>16th December to 22nd December 2022 </b>and provided attendees with valuable insights into the various educational opportunities available in the region. The event brought together a diverse range of educational institutions, including School, colleges, vocational schools, Coaching Institutes and training centers.</p>
                <p>Throughout the expo, attendees had the opportunity to attend informative sessions, interact with representatives from different educational institutions, and participate in workshops. The sessions covered a wide range of topics, including admission requirements, scholarships, financial aid options, and career opportunities.</p>
                <p>In addition to the informative sessions, interactive exhibits, and workshops, the <b>National Book Fair (Urdu Kitab Mela)</b> was also a highlight of the <b>"Aurangabad Education Expo-2022"</b>. Attendees had the opportunity to browse a vast collection of books from various genres and authors, including educational resources, Islamic Literature, and children's books.</p>
                <p><b>The National Book Fair</b> was an excellent addition to the expo, providing attendees with an opportunity to develop their reading habits and enhance their knowledge beyond the classroom. Moreover, the fair was an ideal platform for book enthusiasts, authors, and publishers to come together and discuss the latest trends and developments in the world of literature.</p>
                <p>The interactive exhibits and workshops provided attendees with hands-on experiences and helped them gain a deeper understanding of different educational programs. Additionally, the expo provided a platform for students to interact with industry experts and discuss the latest trends and advancements in their respective fields.</p>
                <p>The <b>"Aurangabad Education Expo-2022"</b> was well received by both students and educational institutions alike. It provided a unique opportunity for educational institutions to showcase their programs and connect with potential students. Similarly, students were able to explore their options and gather information about different educational programs and career paths.</p>
                <p>Overall, the <b>"Aurangabad Education Expo-2022"</b> was a successful event that provided attendees with valuable insights and information about the educational landscape in the region. It played a significant role in helping students make informed decisions about their future educational goals and aspirations.</p>
                <br />
                <h4>Introduction</h4>
                <p>The historical city of Aurangabad witnessed a remarkable educational event from 16th to 22nd December 2022, as the city hosted the Aurangabad Education Expo 2022. The event was organized by a team of dedicated individuals who promised to bring educational opportunities to the citizens of Aurangabad, and they have successfully fulfilled their promise.</p>
                <p>The expo featured over 70 educational institutions that participated in various activities aimed at promoting education and providing learning opportunities to students, teachers, and parents. A total of 165 stalls were set up, with 50 of them dedicated to a book fair. The book fair attracted more than 150,000 visitors, who showed their appreciation for the management skills of the organizing team.</p>
                <p>The event also featured an evening session every day, which brought together some of the country's well-known scholars, intellectuals, and academics. The speakers provided guidance and training to local students, guardians, and educators, and their speeches were well-received by the attendees.</p>
                <p>One of the highlights of the Aurangabad Education Expo 2022 was the Islamic Scientific Exhibition organized by IRC. The exhibition showcased the contributions of Islamic scholars to the development of science and technology and provided visitors with valuable insights into the role of Islam in the advancement of knowledge.</p>
                <p>Overall, the Aurangabad Education Expo 2022 was a resounding success, providing a platform for educational institutions, trainers, and students to come together and share their knowledge and experiences. The event has set a benchmark for educational expos in the region, and its success is a testament to the dedication and hard work of the organizers.</p>

                 <br />
                <h4>Participants Summary:</h4>
                <p>
                The Aurangabad Education Expo 2022 was a grand success, attracting participants from various fields of education. 
                <br />The event brought together over 50 publishers, 70+ Schools, 15+ Colleges, 20+ Coaching institutes, and Training Centers, as well as Social Groups (NGOs), Educational Product Exhibitors, Educational Service Providers, and Food Service Providers. 
                <br />The diverse range of participants at the expo ensured that attendees had access to a wide variety of educational programs, products, and services. 
                <br />The presence of publishers allowed visitors to browse a vast collection of books and educational resources, while schools and colleges showcased their academic programs and extracurricular activities. Vocational schools and training centers provided attendees with an opportunity to learn new skills and gain practical knowledge, while coaching institutes offered guidance and support for students preparing for competitive exams. 
                <br />The social groups (NGOs) showcased their initiatives and programs aimed at promoting education and literacy among underprivileged communities, Educational product exhibitors and service provider’s showcased innovative tools and technologies aimed at enhancing the learning experience, while food service providers ensured that attendees were well-fed and energized throughout the event. 
                <br />Overall, the participation of various educational institutions and service providers made the Aurangabad Education Expo 2022 a comprehensive platform for students and parents to gather information, explore educational opportunities, and make informed decisions about their future. The event was a great success, and the diverse range of participants made it a well-rounded and fulfilling experience for all attendees.
                </p>
                <br />
                <h4>Attendees Survey: </h4>
                <p>Thank you for attending the Aurangabad Education Expo. We value your feedback, and we would like to hear about your experience at the event. Please take a few minutes to complete this survey.</p>
                <p>How did you hear about the Aurangabad Education Expo?</p>
                <p>What was your primary reason for attending the event?</p>
                <ul style={{listStyleType: 'square', marginLeft: 20}}>
                    <li>Did you find the information provided by the exhibitors helpful and informative?</li>
                    <li>Did you attend any informative sessions or workshops? If yes, which ones?</li>
                    <li>Did you find the National Book Fair a valuable addition to the event?</li>
                    <li>Was the event well-organized, and were the exhibitors easily accessible?</li>
                    <li>Were the event staffs helpful and accommodating?</li>
                    <li>Did you find the event to be a comprehensive platform for exploring educational opportunities?</li>
                    <li>Would you recommend the Aurangabad Education Expo to others?</li>
                    <li>Do you have any suggestions for improving the event in the future?</li>
                    
                </ul>
                <p>Thank you for your time and feedback. We hope to see you again at the next Aurangabad Education Expo.</p>
                <br/>
                <h4>Matric Data: </h4>
                <ul style={{listStyleType: 'square', marginLeft: 20}}>
                    <li>Total footfall: 350,000</li>
                    <li>	Unique visitors: 150,000</li>
                    <li>	Non-Muslim visitors: 12,000 to 15,000</li>
                    <li>	Visitors from 10 districts</li>
                    <li>	192+ schools visited the event</li>
                    <li>	32+ guest speakers participated</li>
                    <li>	2,100+ Makatib students visited the event</li>
                    <li>	1,200 teachers visited the event</li>
                    <li>	Print media coverage: 16 houses</li>
                    <li>	YouTube channel coverage: 40+</li>
                    <li>	Social media footprints: 4,000,000 (people from 8 states follow the event online)</li>
                    <li>	Zilla Parishad staff: 480</li>
                    <li>	Value of books sold: 45 lakh+</li>
                    <li>	Wall frames, calligraphy, and artifacts sold by female makers: 7,00,000 Tughare</li>
                    <li>	Revenue generated in the food & fun zone: 20,00,000+</li>
                    <li>	Women entrepreneurs participated: 20+</li>
                    <li>	Number of volunteers who worked sincerely: 240+</li>
                    <li>	Number of volunteers who worked dedicatedly for IRC Museum: 120+</li>
                    <li>	Security guards: 20 (male and female)</li>
                    <li>	Incidents of Casualties, Police Complaints, or Thefts: 0%</li>
                    <li>	NGOs participating in the event: 40+</li>
                    <li>	Certificates distributed: 15,000+</li>
                </ul>
                <p>
                These metrics indicate that the Aurangabad Education Expo 2022 was a successful event that attracted a large and diverse audience, including students, teachers, and members of the public. The event was well-organized and had a significant impact on the local community, with a wide range of organizations and agencies participating. Additionally, the event was able to generate significant revenue through book sales and the food & fun zone, while also supporting women entrepreneurs and providing valuable learning opportunities for students and teachers alike.
                </p>
                </div>
            </div>
            
            {/* Contact Page Start */}
            <Footer />
        </div>
    );
      
    
}

export default ExecutiveReport2022;