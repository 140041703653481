import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Card } from "../Components/Cards";
import * as config from "../config";
import { HeroHeader, Loading, SmallBlogLabel } from "../Components/Elements";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { DiscussionEmbed } from 'disqus-react';

const shareButtonStyle = {
	marginRight: '10px',
	padding: '8px 12px',
	border: '1px solid #ccc',
	borderRadius: '4px',
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	color: '#333',
  };

const BlogDetails = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [rows, setRows] = useState(null);
  const [loadingAPI, setLoadingAPI] = useState(true);

  const disqusShortname = "education-expo"; // Your Disqus shortname
  const disqusConfig = {
    url: window.location.href,
    identifier: slug,
    title: slug,
  };
  const blogsFetch = () => {
    setLoadingAPI(true);
    fetch(`${config.API_URL}BlogList`, {
      method: "GET",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => response.text())
      .then((data) => {
        var rows = JSON.parse(data).data;
        setRows(rows);
        setLoadingAPI(false);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  const blogFetch = () => {
    fetch(`${config.API_URL}GetBlog?view=1&slug=` + slug, {
      method: "GET",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => response.text())
      .then((data) => {
        var data = JSON.parse(data).data;
        setData(data);

        // document.title = data.blog_title + "- Blog Education Expo";
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };

  useEffect(() => {
    document.title = "Blog - Aurangabad Education Expo 2023";
    if (typeof window.loadMainJS === "function") {
      window.loadMainJS();
    }
    window.scrollTo(0, 0);
    blogsFetch();
    blogFetch();
  }, [slug]);

  return (
    <div>
      {/* <Navigation /> */}
      <HeroHeader
        title={
          loadingAPI == true || data == null ? "Loading..." : data.blog_title
        }
      />

      <div className="event-single-page section-padding-02">

        <div className="container">
          <div className="row justify-content-between gx-5">
			{
				loadingAPI == true ? (<div className="shine" style={{width: "751px", height: "20px"}}></div>) : (
					<h4 className="event-title" style={{ fontSize: "24px", lineHeight: "30px", marginBottom: "30px"}}>
					{data == null ? "..." : data.blog_title}
				  </h4>
				)
			}

            <div className="col-lg-8">
              { loadingAPI == true || data == null ? (
				<>
				<div className="shine" style={{width: "100%", height: "390px", marginBottom: "10px"}}></div>
				{/* <div className="shine" style={{width: "100%", height: "20px"}}></div> */}
				</>
              ) : (
                <div className="single-blog">
  <div className="event-header">
  <div className="event-thumbnail" /* style={{backgroundColor: "#bcbcbc", backgroundImage: `url(${item.blog_image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain',backgroundPosition: 'center', height: '390px',width: '751px' }} */>

                 <img src={data.blog_image} alt="Blog Image" className="blog-img" />

             </div>
    {/* <h5 className="event-event-date">
      <span className="month bg-orange">Aug</span>
      <span className="year">2022</span>
    </h5> */}
  </div>
  <div className="event-body">
    <div className="event-details">
      <span className="author" style={{marginRight: "10px"}}> {data.views} views</span>
      <span className="comment"><span><i className="far fa-calendar-alt" style={{marginRight: "10px"}} /></span>{new Date(data.created_at).toLocaleDateString()}</span>
    </div>
	<br/>
    <h4 className="event-title">{data.blog_title}</h4>


    <p className="event-content">{data.short_description}</p>
	<br/>
    <div
            className="text"
            dangerouslySetInnerHTML={{ __html: data.long_description }}
          />


<div className="social-media-sharing">
            <FacebookShareButton url={window.location.href}>
              <div style={shareButtonStyle}>
                <FontAwesomeIcon icon={faFacebook} style={{ marginRight: '5px' }} />
                Share on Facebook
              </div>
            </FacebookShareButton>

            <TwitterShareButton url={window.location.href}>
              <div style={shareButtonStyle}>
                <FontAwesomeIcon icon={faTwitter} style={{ marginRight: '5px' }} />
                Share on Twitter
              </div>
            </TwitterShareButton>

            <LinkedinShareButton url={window.location.href}>
              <div style={shareButtonStyle}>
                <FontAwesomeIcon icon={faLinkedin} style={{ marginRight: '5px' }} />
                Share on LinkedIn
              </div>
            </LinkedinShareButton>
			<DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
          </div>
  </div>


</div>

              )}
            </div>
            <div className="col-lg-4">
              <div className="exvent-sidebar">

                <div className="sidebar-widget" style={{ margin: "0px" }}>
                  <div
                    className="sidebar-offer"
                    style={{
                      backgroundImage:
                        "url(assets/images/bg/sidebar_ad_bg.jpg)",
                    }}
                  >
                    <h3 className="offer" style={{ fontSize: "27px" }}>
                      Register your School
                    </h3>
                    <h3 style={{ fontSize: "15px", color: "white" }}>
                      Join Us at the Education Expo 2023{" "}
                    </h3>
                    <h5 className="offer-title">
                      Showcase Your Students' Performance
                    </h5>
                    <Link to="/SchoolRegistration" className="btn btn-orange">
                      Register now
                    </Link>
                  </div>
                </div>
                <div className="sidebar-widget" style={{ margin_top: 100 }}>
                  <div className="widget-title">
                    <h4>Recent Blogs</h4>
                  </div>
                  <div className="popular-posts">
                    <ul>
					{rows &&
    rows.slice(0, 5).map((item, index) => (
      <li key={index}>

			<div className="row" style={{alignItems: 'center'}}>
				<div className="col-4">
					<div className="post-thumb" style={{
                        backgroundColor: "#bcbcbc",
                        backgroundImage: `url(${item.blog_image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        height: "100px",
                        width: "100px",
                      }}>
					</div>
				</div>
				<div className="col-8">
				<Link to={`/Blog/${item.blog_slug}`} >
					<div className="post-text">
            			<h4 className="title">{item.blog_title}</h4>
            			<span className="post-meta">
            			  <i className="far fa-calendar-alt" />{" "}
            			  {new Date(item.created_at).toLocaleDateString()}
            			</span>
          			</div>
				</Link>
				</div>
			</div>


	  </li>
    ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default BlogDetails;
