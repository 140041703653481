import React from 'react';
import * as config from '../config'
import { HeroHeader } from "../Components/Elements";
import Footer from "../Components/Footer";

const Tour = () => {

  return (
	<>
	  <HeroHeader title="Tour" />
    <div className="gallery-area-2  section-padding-04" style={{padding: "50px 50px"}}>
	<iframe width="100%" height="640" frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowfullscreen scrolling="no" src="https://kuula.co/share/collection/7JRvh?logo=1&info=1&fs=1&vr=0&zoom=1&sd=1&thumbs=1"></iframe>
    </div>
	<Footer />
	</>
  );
};

export default Tour;
