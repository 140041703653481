import React, {useState,useEffect} from 'react';
import {Card} from '../Components/Cards'
import * as config from '../config';
import {HeroHeader, Loading} from '../Components/Elements';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import { Link, NavLink } from "react-router-dom";
const Activities = () =>{
    const [rows, setRows] = useState([]);
    const [loadingAPI, setLoadingAPI] = useState(false);
    useEffect(() => {
        document.title = "Activities - Aurangabad Education Expo 2023";
        if (typeof window.loadMainJS === 'function') {
          window.loadMainJS();
        }
        // window.scrollTo(0, 0);

      }, []);

    useEffect(() => {
        setLoadingAPI(true);
        fetch(`${config.API_URL}ActivitiesList`, {
            method: 'GET',
            headers: {"Content-Type": "application/x-www-form-urlencoded"}
          })
          .then(response => response.text())
          .then(data => {
            var rows = JSON.parse(data).data;
            setRows(rows);
            setLoadingAPI(false);
          })
          .catch(error => {
            // Handle any errors
            console.error(error);
          });
    },[]);
    return (

        <div>
             {/* <Navigation /> */}
            {/* Hero Start */}
            <HeroHeader title="Minds in Motion Activities" />
            {/* Hero End */}

            {/* Event List Page Start */}
            {
            loadingAPI == true?<div style={{padding: 100}}><Loading msg={`Please wait while we loading activities`}/></div>:
            <div className="event-list-page-wrap">
                <div className="container" style={{paddingTop:20, paddingBottom: 20}}>

                {/* Event List Content Start */}
                <div className="event-list-content-wrap">
                    <div className="tab-content">
                    <div className="tab-pane  show active" id="grid">
                            <div className="event-header">
                                <h4 className="event-title">Minds in Motion Activities</h4>
                                <div className="event-thumbnail">
                                    <img src="/assets/images/2023/Post.jpeg" alt="Minds In Motion" className="blog-img" />
                                </div>
                            </div>
                            <div className='row justify-content-end'>

                                <div className='col-lg-3'>
                                    <br />
                                    <a target='_blank' href='../assets/MindsinMotionBrochure.pdf' className='btn btn-primary' >Dowonload Brochure</a>
                                </div>
                            </div>
                            <div className="event-body">
                                <p className="text">Dear Friends and Visionaries,</p>

                       <p> In the pursuit of progress and advancement, education and intellectual development stand as the pillars upon which a thriving society is built. An educated and intelligent community holds the power to transform the world, fostering social, economic, and cultural growth for generations to come.</p>

                       <p>Welcome to <b>"Minds in Motion,"</b> a dynamic social organization that embraces this profound belief and is committed to propelling society forward through the limitless potential of its youth. We firmly believe that equal opportunities are the birthright of every young mind, and we actively engage in nurturing their brilliance. Our mission is simple, yet profound – we pledge to inspire the new generation to become architects of an advanced and enlightened society.</p>

                       <p>At <b>"Minds in Motion,"</b> our vision is focused on building a prosperous, knowledgeable, and capable society that thrives on compassion, creativity, and innovation. We hold an unwavering dedication to nurturing enlightened students, for they shall become the architects of a new and advanced world. Guided by the principles of humanity, ethics, and technological development, we seek to ignite a spark of brilliance within each young soul.</p>

                       <p>Our esteemed members are accomplished individuals in their respective fields, and their passion for service knows no bounds. They stand ready to serve and contribute their best, knowing that true progress is born out of collaboration and shared endeavors. Together, we aim to reach new heights in the journey towards prosperity and progress, with the unwavering support of various social and educational institutions and the essential cooperation of like-minded individuals like you.</p>

                       <p><b>Our array of engaging activities reflects our commitment to empowering young minds:</b></p>

                       <p>The Public Speaking Competition nurtures eloquence and confidence, enabling students to articulate their visions with unwavering grace.</p>
                       <p>The Essay Writing Competition provides a canvas for creativity, allowing students to weave compelling stories and powerful ideas.</p>
                       <p>The GK Quiz Competition ignites the spirit of curiosity, fostering a hunger for knowledge and expanding horizons.</p>
                       <p>The Fanoos-e-Aagahi Competition is a beacon of awareness and education, illuminating the path for those in need.</p>
                       <p>The Drawing Competition celebrates artistic brilliance, bringing cultural heritage to life on vibrant canvases.</p>
                       <p>The Student Catalyst Conference empowers young change-makers, amplifying their ideas for sustainable development.</p>
                       <p>The Youth Parliament encourages spirited debates, nurturing leadership and critical thinking.</p>
                       <p>The Science Projects Proposals inspire scientific inquiry and innovation, paving the way for a brighter tomorrow.</p>
                       <p>Dear friends, the "Minds in Motion" journey is a symphony of aspirations, passions, and dreams. Together, we strive to create a generation of empowered individuals who will shape a brighter, compassionate, and equitable future. Join us in this journey of transformation and discovery, as we unleash the potential within us and the world around us.</p>

                       <p>Let's dare to dream, and together, let's make those dreams a splendid reality.</p>

                       <p>Welcome to "Minds in Motion" – the revolution of intellect and compassion.</p>
                        </div>
                        <div className="row">
                        {rows && rows.map((item, index) => (
                            <Card key={index} img={item.image} title={item.title} timing={item.activity_date} location={`Aurangabad`} description={item.short_description} url={`/ActivityDetail/`+item.slug} />
                        ))}
                        </div>
                    </div>

                    </div>
                </div>
                {/* Event List Content End */}
                {/* <div className="event-next-prev-btn text-center">
                    <a className="event-btn" href="event-list.html"><i className="flaticon-back" /> Previous</a>
                    <a className="event-btn btn-next" href="event-list.html">Next <i className="flaticon-next" /></a>
                </div> */}
                </div>
            </div>}
            {/* Event List Page Start */}
            <Footer />
        </div>
    );

}

export default Activities;
