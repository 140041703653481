import React, {useState,useEffect} from 'react';
import { useParams, NavLink } from "react-router-dom";
import {Card} from '../Components/Cards'
import * as config from '../config';
import {Loading,SmallActivityLabel,Alert,HeroHeader} from '../Components/Elements';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';

const StudentRegistration = () =>{
    const { slug } = useParams();
    const [data, setData] = useState(null);
    const [schools, setSchool] = useState(null);
    const [loadingAPI, setLoadingAPI] = useState(true);
    const [schoolId,setSchoolId] = useState('');
    const [activityId,setActivityId] = useState('');
    const [studentName,setStudentName] = useState('');
    const [studentEmail,setStudentEmail] = useState('');
    const [studentContact,setStudentContact] = useState('');
    const [studentWhatsapp,setStudentWhatsapp] = useState('');
    const [paymentMethod,setPaymentMethod] = useState('');
    const [paymentTXTNumber, setPaymentTXTNumber] = useState('');
    const [responseMessage, setResponseMessage] = useState(null);
    const [responseStatus, setResponseStatus] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(0);
    const activityFetch = () => {
      setLoadingAPI(true);
      fetch(`${config.API_URL}SchoolsList`, {
          method: 'GET',
          headers: {"Content-Type": "application/x-www-form-urlencoded"}
        })
        .then(response => response.text())
        .then(data => {
          var rows = JSON.parse(data).data;
          setSchool(rows);
          setLoadingAPI(false);
        })
        .catch(error => {
          // Handle any errors
          console.error(error);
        });
    };
    const activitiesFetch = ()=>{
      fetch(`${config.API_URL}ActivityDetail?slug=`+slug, {
        method: 'GET',
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
      })
      .then(response => response.text())
      .then(data => {
        var data = JSON.parse(data).data;
        setData(data);
        document.title = data[0].title;
        document.title = "Enroll in "+data[0].title;
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });
    };

    useEffect(() => {

        if (typeof window.loadMainJS === 'function') {
          window.loadMainJS();
        }
        window.scrollTo(0, 0);

        activityFetch();
        activitiesFetch();
      }, [slug]);
      const handleSubmit = (e)=>{
        e.preventDefault();
        const formData = {
          schoolId,
          activityId,
          studentName,
          studentEmail,
          studentContact,
          studentWhatsapp,
          paymentMethod,
          paymentTXTNumber
          // Other form field values
        };
        setLoadingStatus(1);
        fetch(`${config.API_URL}SchoolRegistration`, {
          method: 'POST',
          headers: {"Content-Type": "application/x-www-form-urlencoded"},
          body: JSON.stringify(formData),
        })
        .then(response => response.text()) // Convert the response to text
        .then(data => {
            setLoadingStatus(0);
            if(JSON.parse(data).success == true){
              // Reset form fields
                setSchoolId('');
                setActivityId('');
                setStudentName('');
                setStudentEmail('');
                setStudentContact('');
                setStudentWhatsapp('');
                setPaymentMethod('');
                setPaymentTXTNumber('');
            }
            setResponseStatus(JSON.parse(data).success);
            setResponseMessage(JSON.parse(data).message);

        })
        .catch(error => {
          setLoadingStatus(0);
          // Handle any errors
          console.error(error);
          setResponseStatus(false);
          setResponseMessage(error.message);
        });
      };
    return (
        <div>
            {/* <Navigation /> */}
            <HeroHeader title={loadingAPI == true || data == null?"Loading...":+"Enrollment - "+ (data == null?"...":data[0].title)} />
            <div className="login-register-area section-padding-02">
                <div className="container">
                    <div className="row justify-content-center g-5">
                        <div className="col-lg-6 col-md-8">
                        <div className="hero-form mt-0 grey-bg">
                            <h3 className="form-title black">Enrollment in {data == null?"...":data[0].title}</h3>
                            <form action="#" className="exvent-form"  onSubmit={handleSubmit}>
                            <div className="row gy-4">

                                <div className="col-12">
                                    <div className="single-input white-bg">
                                        <select className='form-control' placeholder="School Medium" value={schoolId} onChange={(e) => setSchoolId(e.target.value)} >
                                        <option value="" selected disabled>Select School</option>
                                        {schools && schools.map((item, index) => (
                                            (<option value={item.school_id} key={index}>{item.school_name}</option>)
                                        ))}

                                        </select>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="single-input white-bg">
                                        <input type="text" placeholder="Student Name" value={studentName} onChange={(e) => setStudentName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="single-input white-bg">
                                        <input type="number" placeholder="Student Contact Number" value={studentContact} onChange={(e) => setStudentContact(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="single-input white-bg">
                                        <input type="text" placeholder="Student Whatsapp Number" value={studentWhatsapp} onChange={(e) => setStudentWhatsapp(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="single-input white-bg">
                                        <input type="email" placeholder="School E-Mail" value={studentEmail} onChange={(e) => setStudentEmail(e.target.value)} />
                                    </div>
                                </div>





                                <div className="col-12">
                                {loadingStatus == 1? (<Loading msg="Please wait..."/>):("") } {responseStatus !== null && (<Alert msg={responseMessage} type={responseStatus == true?"success":"danger"} />)}
                                </div>
                                <div className='col-12' id='resp'></div>
                                <div className="col-sm-5 text-center">
                                <button type="submit" disabled={loadingStatus==1?true:false} className="submit_btn" disabled>Entroll & Pay</button>
                                </div>
                            </div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}
export default StudentRegistration;
