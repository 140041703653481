import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as config from "../config";
import { LoadingOverlay, Alert } from "../Components/Elements";

function ScheduleRegisterForm({ ScheduleId }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [responseMessage, setResponseMessage] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(0);



  useEffect(() => {

  }, []);

  const [formData, setFormData] = useState({
    expo_id: "",
    schedule_activity_id: ScheduleId,
    participant_name: "",
    participant_gender: "",
    participant_contact_number: "",
    participant_whatsapp_number: "",
    participant_designation: "",
    participant_email: "",
    participant_organization:""
  });

  const clearResponse = () => {
    setTimeout(() => {
      setResponseStatus(null);
      setResponseMessage(null);
    }, 2000);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };



  const handleSubmit = async () => {
    setLoadingStatus('1')
    console.log(formData)
    try {
      const data = await fetch(`${config.API_URL}ParticipantsRegistration`, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: JSON.stringify(formData),
      });
      const res = await data.json();
      setLoadingStatus('0')
      if (res.success == true) {
        fetch(res.path)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const blobUrl = URL.createObjectURL(blob);
        
        // Create a link element
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', 'RegistrationCertificate.pdf'); // Set the desired filename
        
        // Simulate a click to trigger the download
        document.body.appendChild(link);
        link.click();
        
        // Clean up
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Error fetching the file:', error);
      });
      } else {
        if (res.validate) {
          const inputElements = document.querySelectorAll(`input[name="${res.param}"]`);

          inputElements.forEach((inputElement) => {
            inputElement.style.border = "1px solid red";
          });
          setTimeout(() => {
            inputElements.forEach((inputElement) => {
              inputElement.style.border = ""; // Remove the border by setting it to an empty string
            });
          }, 2000);
        }
        setResponseStatus(false);
        setResponseMessage(res.message);
        clearResponse();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button
        className="btn btn-primary"
        style={{ margin: "30px 0", width: "100%" }}
        onClick={handleShow}
      >
        Register Now
      </Button>
      <Modal size="xl" show={show} onHide={handleClose} scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>Event Registration Form </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Name</label>
            <input
              className="form-control"
              type="text"
              onChange={handleInputChange}
              placeholder="Name"
              name="participant_name"
            />
          </div>
          <div className="form-group">
            <label>Organization</label>
            <input
              className="form-control"
              type="text"
              onChange={handleInputChange}
              placeholder="Organization"
              name="participant_organization"
            />
          </div>
          
          <div className="form-group">
            <label>Designation</label>
            <select
              className="form-control"
              onChange={handleInputChange}
              placeholder="Designation"
              name="participant_designation"
            >
              <option value="Educational Management">Educational Management</option>
              <option value="Principle">Principle</option>
              <option value="Teacher">Teacher</option>
              <option value="Parent">Parent</option>
              <option value="Student">Student</option>
              <option value="other">other</option>
            </select>
          </div>
          
          <div className="form-group">
            <label>Gender</label>
            <select
              className="form-control"
              onChange={handleInputChange}
              placeholder="Gender"
              name="participant_gender"
            >
              <option value="" disabled selected>Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>

          <div className="form-group">
            <label>Contact Number</label>
            <input
              className="form-control"
              onChange={handleInputChange}
              placeholder="Contact Number"
              type="number"
              name="participant_contact_number"
            />
          </div>
          <div className="form-group">
            <label>Whatsapp Number</label>
            <input
              className="form-control"
              onChange={handleInputChange}
              placeholder="Whatsapp Number"
              type="number"
              name="participant_whatsapp_number"
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              className="form-control"
              onChange={handleInputChange}
              placeholder="Email"
              name="participant_email"
            />
          </div>

          {loadingStatus == 1 ? (
            <LoadingOverlay
              msg=" <p>Please wait while we Register your and prepare your Receipt.</p>
						<p>Do not close this tab.</p>
						<p>Your Receipt will be automatically downloaded and sent to the email you provided.</p>"
            />
          ) : (
            ""
          )}{" "}
          {responseStatus !== null && (
            <Alert
              msg={responseMessage}
              type={responseStatus == true ? "success" : "danger"}
            />
          )}
          <br />
          <Button variant="primary" onClick={handleSubmit}>
            {" "}
            Submit{" "}
          </Button>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default ScheduleRegisterForm;
