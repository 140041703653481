import React, {useState,useEffect} from 'react';
import { useParams } from "react-router-dom";
import {Card} from '../Components/Cards'
import * as config from '../config';
import {HeroHeader,Loading,SmallActivityLabel} from '../Components/Elements';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';

const PressReleaseDetails = () =>{
  // return (<div></div>);
    const { slug } = useParams();
    const [data, setData] = useState(null);
    const [rows, setRows] = useState(null);
    const [loadingAPI, setLoadingAPI] = useState(true);

    const activityFetch = () => {
      setLoadingAPI(true);
      fetch(`${config.API_URL}PRList`, {
          method: 'GET',
          headers: {"Content-Type": "application/x-www-form-urlencoded"}
        })
        .then(response => response.text())
        .then(data => {
          var rows = JSON.parse(data).data;
          setRows(rows);
          setLoadingAPI(false);
        })
        .catch(error => {
          // Handle any errors
          console.error(error);
        });
    };
    const activitiesFetch = ()=>{
      fetch(`${config.API_URL}PressReleaseDetail?slug=`+slug, {
        method: 'GET',
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
      })
      .then(response => response.text())
      .then(data => {
        var data = JSON.parse(data).data;
        setData(data);
        document.title = data[0].title;
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });
    };

    useEffect(() => {
        document.title = "Press Release - Aurangabad Education Expo 2023";
        if (typeof window.loadMainJS === 'function') {
          window.loadMainJS();
        }
        window.scrollTo(0, 0);

        activityFetch();
        activitiesFetch();
      }, [slug]);

    return (
        <div>
            {/* <Navigation /> */}
            <HeroHeader title={loadingAPI == true || data == null?"Loading...":data[0].pr_title} />

            <div className='event-single-page section-padding-02'>
              <div className="container">
                  <div className='row justify-content-between gx-5'>
                    <div className='col-lg-8'>
                      {
                        loadingAPI == true?<Loading msg={`Please wait...`}/>:
                        <div className="single-event">
                          <div className="event-header">
                            <h4 className="event-title">{data == null?"...":data[0].pr_title}</h4>
                            <div className="event-thumbnail">
                            <img src={data == null?"assets/images/blog_img_1.jpg":data[0].pr_image} alt="Blog Image" className="blog-img" />
                            </div>
                          </div>
                          <div className="event-body">
                            {/* <p className="text">{data == null?"...":data[0].pr_desc}</p> */}
							<div
            className="text"
            dangerouslySetInnerHTML={{  __html: data[0].pr_desc }}
          />
                          </div>
                        </div>}
                      </div>
                      <div className='col-lg-4'>
                    <div className='exvent-sidebar'>
                      <div className="sidebar-widget">
                        <div className="event-price-details">

                          <div className="all-details">
                            <h4 className="title">Press Releases</h4>
                            {rows && rows.map((item, index) => (
                                <SmallActivityLabel key={index} title={item.pr_title} date={item.created_date} slug={item.pr_slug} />
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  </div>

              </div>
            </div>
            <Footer />
        </div>
    );
}
export default PressReleaseDetails;
