import React from 'react';
import {HeroHeader, Alert} from '../Components/Elements'
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import * as config from '../config'
import axios from 'axios';
class Contact extends React.Component{
  componentDidMount(){
    document.title = "Contact - Aurangabad Education Expo 2023";
    // window.scrollTo(0, 0);
  }
  constructor(props) {
    super(props);
    this.state = {
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        successMessage: '',
        errorMessage: '',
    };
}

handleSubmit = (e) => {
    e.preventDefault();

    axios.get(config.API_URL + 'contact', {
      params: {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        subject: this.state.subject,
        message: this.state.message,
      }
    })
        .then((response) => {
            this.setState({ successMessage: response.data.message });
        })
        .catch((error) => {
            // Handle error, e.g., display an error message
            this.setState({ errorMessage: 'An error occurred while submitting the form.' });
        });
};


handleInput = (e) => {
  const { name, value } = e.target;
  this.setState({ [name]: value });
};

  render(){
    return (
      <div>
       {/* <Navigation /> */}
      {/* Hero Start */}
      <HeroHeader title="Contact us" />
      {/* Hero End */}
      {/* Contact Page Start */}
      <div className="contact-form-section section-padding-02">
        <div className="container">
          <div className="row g-5 justify-content-between">
            <div className="col-lg-5">
              <div className="section-title-wrap">
                <div className="section-title">
                  <h5 className="sub-title orange">Get Information</h5>
                  <h2 className="title">Contact With Us</h2>
                </div>
              </div>
              <div className="single-contact">
                <span className="contact-icon">
                  <i className="fas fa-map-marker-alt" />
                </span>
                <div className="contact-info">
                  <h4 className="contact-label">Our Location : </h4>
                  <p className="address">
                  <b>Corespondence Address:</b><br />
                  Graphicon Design Studio, Delhi Gate, Aurangabad, Maharashtra - India
                  <br />
                  <b>Registered Address:</b><br />
                  CTS No. 11315/67, Plot No. 102, Aurangabad Times Colony, Kat Kat Gate, Aurangabad-431001, Maharashtra
                    </p>
                </div>
              </div>
              <div className="single-contact">
                <span className="contact-icon orange-color">
                  <i className="flaticon-phone-call" />
                </span>
                <div className="contact-info">
                  <h4 className="contact-label">Contacts</h4>
                  <a href="tel:+4581236572324" className="address">

                    <br />+91 8055459200 (Basit Siddiqui)
                    <br />+91 9371717130 (Afroz Khan)
                    <br />+91 87673 33899 (Whatsapp)
                  </a>
                </div>
              </div>
              <div className="single-contact">
                <span className="contact-icon blue-color">
                  <i className="far fa-envelope" />
                </span>
                <div className="contact-info">
                  <h4 className="contact-label">Email :</h4>
                  <a href="mailto:admin@educationexpo.org" className="address">admin@educationexpo.org</a>
                  <a href="mailto:mail2educationexpo@gmail.com" className="address">mail2educationexpo@gmail.com</a>

                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="map-section">
                <div className="map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3751.7468411629134!2d75.328176!3d19.892909000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdb981014c102d5%3A0xe6031b4285b4b7b4!2sAamkhas%20Maidan!5e0!3m2!1sen!2sus!4v1688305866909!5m2!1sen!2sus" allowFullScreen loading="lazy" />
                </div>
              </div>
            </div>
          </div>

          {/* Contact Form Start */}
          <div className="contact-form-wrap">
            <form onSubmit={this.handleSubmit} className="contact-form">
              <div className="row">
                <div className="col-12">
                  <div className="section-title-wrap text-center">
                    <div className="section-title">
                      <h2 className="title">Send Us A Message</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-4 justify-content-center">
                <div className="col-lg-6">
                  <input type="text" className="comment-form-input" placeholder="Your Name" name='name' value={this.state.name} onChange={this.handleInput} autoComplete="name"
  onInput={this.handleInput} />
                </div>
                <div className="col-lg-6">
                  <input type="email" className="comment-form-input" placeholder="Your Email" name='email' value={this.state.email} onChange={this.handleInput} autoComplete="email"
  onInput={this.handleInput}  />
                </div>
                <div className="col-lg-6">
                  <input type="tel" className="comment-form-input" placeholder="Phone Number" name='phone' value={this.state.phone} onChange={this.handleInput} />
                </div>
                <div className="col-lg-6">
                  <input type="text" className="comment-form-input" placeholder="Subject" name='subject' value={this.state.subject} onChange={this.handleInput} />
                </div>
                <div className="col-12">
                  <textarea name="message" placeholder="Your Message" className="comment-form-input" value={this.state.message} onChange={this.handleInput} />
                </div>

                {this.state.successMessage ? (
                        <Alert
                          msg={this.state.successMessage}
                          type={ "success"}
                        />
                  ): ("")}
                <div className="col-lg-3">
                  <button type="submit" className="submit_btn">Submit Now</button>
                </div>
              </div>
            </form>
          </div>
          {/* Contact Form End */}
        </div>
      </div>
      {/* Contact Page Start */}
      <Footer />
    </div>
    );
  }
}

export default Contact;

