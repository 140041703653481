import React from 'react';
import {HeroHeader} from '../Components/Elements';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import { Link } from "react-router-dom";

var i = 0;
class About extends React.Component{
  componentDidMount(){

    document.title = "About - Aurangabad Education Expo 2023";
    if(i == 0){
      // eslint-disable-next-line

      i++;
    }
    // window.scrollTo(0, 0);
  }

  render(){
    return (<div>
      {/* <Navigation /> */}
      {/* Hero Start */}
      <HeroHeader title="About us" />
      {/* Hero End */}
      <div className="about-section about-page section-padding-03">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title">
                  <h5 className="sub-title orange">Aurangabad Education Expo 2023</h5>
                  <h2 className="title">Ignite your curiosity. Find inspiration. Shape your future.</h2>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="about-right-content">
                  <div className="section-paragraph">
                    <p>Welcome to Aurangabad Education Expo - 2023! Prepare for a transformative educational journey that opens doors to boundless opportunities. As a leading regional event, AEE-2023 unites students, educators, and industry professionals to delve into the forefront of education. Explore the latest trends, innovations, and advancements in the field through diverse activities, workshops, and interactive sessions. Our aim is to inspire, empower, and equip you with the knowledge and skills necessary to forge a prosperous future. Join us and become part of an extraordinary educational experience at AEE-2023, where limitless possibilities await.</p>
                  </div>
                  <div className="about-btn">
                    <Link className="btn btn-orange" to="/Contact">Contact for booking</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0" style={{marginTop: "50px"}}>
              <div className="col-lg-7 order-lg-1 order-2">
                <div className="about-thumb-wrap">
                  <img src="assets/images/about_page_img.jpg" alt="about_img1" />
                </div>
              </div>
              <div className="col-lg-5 order-lg-1 order-1">
                <div className="about-content-wrap">
                  <img src="assets/images/shape/about_content_shape1.png" alt="shape" className="about-box-shape" />
                  <img src="assets/images/shape/about_shape1.png" alt="shape x" className="about-shape-x" />
                  <div className="section-title">
                    <h5 className="sub-title orange">Why attend event</h5>
                    <h2 className="title">Great opportunity to develop you</h2>
                  </div>
                  <div className="about-item">
                    <img src="assets/images/about_icon_1.png" alt="Thumb" className="icon" />
                    <div className="about-item-content">
                      <h5 className="item-heading">7 Days Education Expo</h5>
                      <p className="item-description">Join us for an immersive 7-day Education Expo! Explore a world of knowledge, innovation, and inspiration as we bring together students, educators, and experts from various fields.</p>
                    </div>
                  </div>
                  <div className="about-item">
                    <img src="assets/images/about_icon_2.png" alt="Thumb" className="icon" />
                    <div className="about-item-content">
                      <h5 className="item-heading">21 Workshops & Seminars</h5>
                      <p className="item-description">Don't miss this chance to connect, learn, and create your path to success. Mark your calendars for an unforgettable week of exploration and growth at the 7-day Education Expo.</p>
                    </div>
                  </div>
                  <div className="about-item">
                    <img src="assets/images/about_icon_3.png" alt="Thumb" className="icon" />
                    <div className="about-item-content">
                      <h5 className="item-heading">National Urdu Book Fair</h5>
                      <p className="item-description">Discover the richness and diversity of Urdu literature at the National Urdu Book Fair, where books come alive and stories find their voices.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
                <div className="about-right-content">
                  <div className="section-paragraph">
                    <p>
                      <h5>Welcome to the official website of Aurangabad Education Expo 2023!
</h5>
                      Aurangabad Education Expo is a highly anticipated annual event that brings together education professionals, students, parents, and industry experts to explore the latest trends and advancements in the field of education. Our mission is to create a platform that fosters knowledge sharing, networking, and collaboration, ultimately contributing to the growth and development of the education sector in our region.
                    </p>
                    <p>
                      <h5>Event History:</h5>
                      Building on the resounding success of Aurangabad Education Expo 2022, which took place from 16th to 22nd December 2022, we are thrilled to announce the upcoming Aurangabad Education Expo 2023. This year, our event promises to be bigger, better, and more impactful than ever before. We are proud to have hosted the National Urdu Book Fair as part of our comprehensive educational experience.
                    </p>
                    <p>
                      <h5>Event Details:</h5>
                      Aurangabad Education Expo 2023 will be held from 15th to 21st December 2023 at the Aam-Khas Ground, Aurangabad. This prestigious venue offers a spacious and dynamic setting for exhibitors, workshops, seminars, and interactive sessions.
                    </p>
                    <p>
                      <h5>What to Expect:</h5>
                      <br />- Engaging Exhibits: Explore a wide range of educational products, services, and solutions showcased by leading organizations and institutions. Don't miss the captivating "Quran & Modern Science Exhibition" that highlights the fascinating intersections between the Quranic teachings and scientific advancements.
                      <br />- Insightful Workshops: Participate in interactive workshops conducted by renowned experts, aimed at enhancing teaching methodologies and student learning outcomes.
                      <br />- Thought-Provoking Seminars: Attend thought-provoking seminars on the latest trends and advancements in various fields of education.
                      <br />- Networking Opportunities: Connect with industry professionals, educators, and like-minded individuals to build valuable connections and collaborations.
                      <br />- National Urdu Book Fair: Immerse yourself in a treasure trove of Urdu literature, featuring books from esteemed authors and publishers.
                    </p>
                    <p>
                    Join us as we embark on this educational journey, inspiring and empowering individuals to reach their full potential. Stay tuned for updates, registration details, and an exciting lineup of events on our website.

                    <br />We look forward to welcoming you to Aurangabad Education Expo 2023!


                    </p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      <Footer />
    </div>);
  }
}

export default About;

