import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import * as config from "../config";
import axios from 'axios';
const Navigation = () => {
  const [motions, setMotions] = useState([]);
  const [logo, setLogo] = useState('/assets');
  const handleImageError = () => {
    setLogo( "../assets");
  }
  useEffect(() => {axios.get(`${config.API_URL}ActivitiesList`)
  .then((response) => {
    const rows = response.data.data; // Assuming the data structure is similar

    setMotions(rows);
    // window.hidePreload();
  })
  .catch((error) => {
    // Handle any errors that occurred during the request
    console.error('Error fetching data:', error);
  });
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
	document.querySelector('#offcanvas-close').click();
  }, [pathname]);

  return (
    <div>
      {/* Header Start  */}
      <div id="header" className="section exvent-header-section">
        <div className="container">
          {/* Header Wrap Start  */}
          <div className="header-wrap">
            <div className="header-logo">
              <Link to="/">
                <img
                  src={`${logo}/logo/logo.png`}
                  height={80}
				  onError={handleImageError}
                  alt=""
                />
              </Link>
            </div>
            <div className="header-menu d-none d-lg-block">
              <ul className="main-menu">
                <li>
                      <Link to="/">Home</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/About">About us</Link>
                    </li>
                    <li>
                      <Link to="/ExecutiveReport2022">
                        EduExpo 2022 Executive Report
                      </Link>
                    </li>
                    <li>
                      <Link to="/Report">
                        EduExpo 2023 Executive Report
                      </Link>
                    </li>
                    <li>
                      <a target="_blank" href="https://2022.educationexpo.org/">
                        EduExpo 2022 Website
                      </a>
                    </li>
                    <li>
                      <Link to="/OrganizingCommittee">
                        AEE2023 Organizing Committee
                      </Link>
                    </li>
					<li>
                      <Link to="/" >Our Policies</Link>
                        <ul className="sub-menu">
                          <li><Link to="/PrivacyPolicy">Privacy Policy</Link></li>
                          <li><Link to="/RefundPolicy">Refund Policy</Link></li>
                          <li><Link to="/SponsorshipPolicy" replace>Sponsership Policy</Link></li>
                          <li><Link to="/TermsAndConditions">Terms & Condition Policy</Link></li>
                        </ul>
                      </li>
                  </ul>
                </li>
                <li>
                  <a>
                    <Link to="/Activities">Minds in Motion</Link>
                  </a>
                  <ul className="sub-menu">
                    {motions.map((item, index) => (
                      <li>
                        <Link
                          key={item.activity_id}
                          to={`/ActivityDetail/` + item.slug}
                        >
                          {item.short_title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>

                <li>
                  <a href="#">AEE2023 Schedule</a>
                  <ul className="sub-menu">
                    <li>
                      <a href="/Schedules">All Schedule</a>
                    </li>
                    <li>
                      <a href="/SchedulesIndoors">House of Wisdom Schedule</a>
                    </li>
                    <li>
                      <a href="/SchedulesOutdoors">Open Stage Schedule</a>
                    </li>
                    <li>
                      <a href="/Speakers">Speakers</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Press</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/PressReleases">Press Releases</Link>
                    </li>
                    <li>
                      <Link to="/PressMaterials">Press Material</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/Blogs">Blogs</Link>
                </li>
                {/* <li><Link to="/Stalls">Stalls</Link></li> */}
                {/* <li><Link to="/SchoolRegistration">School Registration</Link></li> */}
                <li>
                  <Link to="/Contact">Contact us</Link>
                </li>
              </ul>
            </div>
            {/* Header Meta Start */}
            <div className="header-meta">
              {/* Header Search Start */}
              {/* <div className="header-search">
                            <a className="search-btn" href="#"><i className="flaticon-loupe"></i></a>
                            <div className="search-wrap">
                                <div className="search-inner">
                                    <i id="search-close" className="flaticon-close search-close"></i>
                                    <div className="search-cell">
                                        <form action="#">
                                            <div className="search-field-holder">
                                                <input className="main-search-input" type="search" placeholder="Search Your Keyword...">
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> */}
              {/* Header Search End */}
              {/* Header Cart Start */}
              {/* Header Cart End */}
              <div className="header-btn d-none d-xl-block">
                <Link className="btn" to="/Report" replace>
                  Executive Report
                </Link>
              </div>
              {/* Header Toggle Start */}
              <div className="header-toggle d-lg-none">
                <button
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExample"
                >
                  <span />
                  <span />
                  <span />
                </button>
              </div>
              {/* Header Toggle End */}
            </div>
            {/* Header Meta End  */}
          </div>
          {/* Header Wrap End  */}
        </div>
      </div>
      {/* Header End */}
      {/* Offcanvas Start*/}
      <div className="offcanvas offcanvas-start" id="offcanvasExample">
        <div className="offcanvas-header">
          {/* Offcanvas Logo Start */}
          <div className="offcanvas-logo">
            <Link to="/">
              <img
                src={logo+"/logo/logo.png"}

                alt=""
              />
            </Link>
          </div>
          {/* Offcanvas Logo End */}
          <button
            type="button"
			id="offcanvas-close"
            className="close-btn"
            data-bs-dismiss="offcanvas"
          >
            <i className="flaticon-close" />
          </button>
        </div>
        {/* Offcanvas Body Start */}
        <div className="offcanvas-body">
          <div className="offcanvas-menu">
            <ul className="main-menu">
              <li className="active-menu">
                <a href="#">Home</a>
                <ul className="sub-menu">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/About">About us</Link>
                  </li>
                  <li>
                    <Link to="/ExecutiveReport2022">
                      EduExpo 2022 Executive Report
                    </Link>
                  </li>
                  <li>
                    <Link to="/OrganizingCommittee">
                      AEE2023 Organizing Committee
                    </Link>
                  </li>

                  <li>
                    <Link to="/">Our Policies</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/PrivacyPolicy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/RefundPolicy">Refund Policy</Link>
                      </li>
                      <li>
                        <Link to="/SponsorshipPolicy" replace>
                          Expo Policies
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/Activities">Minds in Motion</Link>
                <ul className="sub-menu">
                  {motions.map((item) => (
                    <li>
                      <Link
                        key={item.activity_id}
                        to={`/ActivityDetail/` + item.slug}
                      >
                        {item.short_title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>

              <li>
                <a href="#">AEE2023 Schedule</a>
                <ul className="sub-menu">
                  <li>
                    <a href="/Schedules">House of Wisdom Schedule</a>
                  </li>
                  <li>
                    <a href="/Schedules">Open Stage Schedule</a>
                  </li>
                  <li>
                    <a href="/Schedules">Educational Trip Schedule</a>
                  </li>
                  <li>
                    <a href="/Speakers">Speakers</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#">Press</a>
                <ul className="sub-menu">
                  <li>
                    <Link to="#">Press Releases</Link>
                  </li>
                  <li>
                    <Link to="#">Press Material</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/Blogs" replace>
                  Blogs
                </Link>
              </li>
              <li>
                <Link to="/Stalls">Stalls</Link>
              </li>
              <li>
                <Link to="/SchoolRegistration">School Registration</Link>
              </li>
              <li>
                <Link to="/Contact">Contact us</Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Offcanvas Body End */}
      </div>
      {/* Offcanvas End */}
    </div>
  );
};

export default Navigation;
