import React from 'react';
import * as config from '../config';
import { Link, NavLink } from "react-router-dom";
export const Card = ({img, title, timing, location, description, url})=>{

    return (

        <div className="col-lg-4 col-sm-6">
          {/* Event List Item Start */}
          <div className="event-list-item">
            <div className="event-img">
            <NavLink  to={url} replace><img src={img} style={{width: "100%"}} alt={title} /* height={180} */ /></NavLink>
              {/* <span className="cat">Featured</span> */}
            </div>
            <div className="event-list-content">
              {/* <div className="event-price">
                <span className="price">$25</span>
              </div> */}
              <h3 className="title"><NavLink  to={url} replace>{title} </NavLink></h3>
              <div className="meta-data">
                <span><i className="far fa-clock" /> {timing}</span>
                <span><i className="fas fa-map-marker-alt" /> {location}</span>
              </div>
              <div className="event-desc">
                <p>{description}</p>
              </div>
              {/* <a className="ticket-link" href={`#`}>Know More</a> */}
              <NavLink  to={url} replace>Know More</NavLink>
            </div>
          </div>
          {/* Event List Item End */}
        </div>
      );

}
