import React, {useState,useEffect} from 'react';
import * as config from '../config';
import {HeroHeader, Loading} from '../Components/Elements';
import Footer from '../Components/Footer';
import { Link, NavLink } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Blogs = () =>{
    const [rows, setRows] = useState([]);
    const [loadingAPI, setLoadingAPI] = useState(false);
    useEffect(() => {
        document.title = "Blogs - Aurangabad Education Expo 2023";
        if (typeof window.loadMainJS === 'function') {
          window.loadMainJS();
        }
        // window.scrollTo(0, 0);

      }, []);

    useEffect(() => {
        setLoadingAPI(true);
        fetch(`${config.API_URL}BlogList`, {
            method: 'GET',
            headers: {"Content-Type": "application/x-www-form-urlencoded"}
          })
          .then(response => response.text())
          .then(data => {
            var rows = JSON.parse(data).data;
            setRows(rows);
            setLoadingAPI(false);
          })
          .catch(error => {
            // Handle any errors
            console.error(error);
          });
    },[]);
    return (

        <div>
             {/* <Navigation /> */}
            {/* Hero Start */}
            <HeroHeader title="Blogs" />
            {/* Hero End */}

            {/* Event List Page Start */}
            {
            loadingAPI == true?<div style={{padding: 100}}><Loading msg={`Please wait while we loading Blogs`}/></div>:
            <div className="blog-list-page section-padding-04">
  <div className="container">
    <div className="row justify-content-between gx-5">
      <div className="col-lg-8">

        {rows && rows.map((item, index) => (

           <div className="single-blog">
           <div className="blog-header">

             <div className="blog-thumbnail" /* style={{backgroundColor: "#bcbcbc", backgroundImage: `url(${item.blog_image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain',backgroundPosition: 'center', height: '390px',width: '751px' }} */>
				 <Link to={"/Blog/"+item.blog_slug}>

                 <img src={item.blog_image} alt="Blog Image" className="blog-img" />

                </Link>
             </div>

             <h5 className="blog-event-date">
               <span className="month bg-orange">{new Date(item.created_at).getDate()} {new Date(item.created_at).toLocaleDateString('en-US', { month: 'short' })}</span>
               <span className="year">2023</span>
             </h5>
           </div>
           <div className="blog-body">
             <div className="blog-details">
               <span><i className="far fa-user" /> Education Expo Team</span>
               <span><i className="far fa-calendar-alt" /></span> {new Date(item.created_at).toLocaleDateString()}
             </div>
             <Link to={"/Blog/"+item.blog_slug}>
               <h4 className="blog-title">{item.blog_title}</h4>
             </Link>
             <p className="blog-content">{item.short_description}</p>

               <div className="social-media-sharing">
            <FacebookShareButton url={"/Blog/"+item.blog_slug}>
              <FontAwesomeIcon icon={faFacebook} />
            </FacebookShareButton>

            <TwitterShareButton url={"/Blog/"+item.blog_slug}>
              <FontAwesomeIcon icon={faTwitter} />
            </TwitterShareButton>

            <LinkedinShareButton url={"/Blog/"+item.blog_slug}>
              <FontAwesomeIcon icon={faLinkedin} />
            </LinkedinShareButton>
          </div>
           </div>
         </div>
    // <h1 key={index}>{item.blog_title}</h1>
))}

        {/* Pagination Start  */}
        {/* <div className="blog-pagination">
          <nav>
            <ul className="pagination justify-content-center">
              <li className="page-item">
                <a className="page-link arrow" href="#">
                  <i className="fas fa-angle-left" />
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">1</a>
              </li>
              <li className="page-item">
                <a className="page-link active" href="#">2</a>
              </li>
              <li className="page-item">
                <a className="page-link arrow" href="#">
                  <i className="fas fa-angle-right" />
                </a>
              </li>
            </ul>
          </nav>
        </div> */}
        {/* Pagination End  */}
      </div>
      <div className="col-lg-4">
        <div className="exvent-sidebar">
          {/* <div className="sidebar-widget sidebar-widget-no-margin">
            <form className="search-form" action="#">
              <input type="text" placeholder="Search" />
              <button type="submit"><i className="fas fa-search" /></button>
            </form>
          </div> */}
    <div className="sidebar-widget" style={{margin: "0px"}}>
    <div className="sidebar-offer" style={{backgroundImage: 'url(assets/images/bg/sidebar_ad_bg.jpg)'}}>
      <h3 className="offer" style={{fontSize: "27px"}}>Register your School</h3>
      <h3 style={{fontSize:"15px", color: "white"}}>Join Us at the Education Expo 2023 </h3>
	  <h5 className='offer-title'>Showcase Your Students' Performance</h5>
      <Link to="/SchoolRegistration" className="btn btn-orange">Register now</Link>
    </div>
  </div>
  <div className="sidebar-widget" style={{ margin_top: 100 }}>
    <div className="widget-title">
      <h4>Recent Blogs</h4>
    </div>
    <div className="popular-posts">
      <ul>
	  {rows &&
    rows.slice(0, 5).map((item, index) => (
      <li key={index}>
			<div className="row" style={{alignItems: 'center'}}>
				<div className="col-4">
					<div className="post-thumb" style={{
                        backgroundColor: "#bcbcbc",
                        backgroundImage: `url(${item.blog_image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "100px",
                        width: "100px",
                      }}>
					</div>
				</div>
				<div className="col-8">
				<Link to={`/Blog/${item.blog_slug}`} >
					<div className="post-text">
            			<h4 className="title">{item.blog_title}</h4>
            			<span className="post-meta">
            			  <i className="far fa-calendar-alt" />{" "}
            			  {new Date(item.created_at).toLocaleDateString()}
            			</span>
          			</div>
				</Link>
				</div>
			</div>
	  </li>
    ))}

      </ul>
    </div>
  </div>
        </div>
      </div>
    </div>
  </div>
</div>


            }
            {/* Event List Page Start */}
            <Footer />
        </div>
    );

}

export default Blogs;
