import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as config from "../config";
import {
  Loading,
  LoadingOverlay,
  Alert,
  HeroHeader,
} from "../Components/Elements";
import Footer from "../Components/Footer";
import useRazorpay from "react-razorpay";
import axios from "axios";
import Select from "react-select";

const ActivityEnrollment = () => {
  const [Razorpay] = useRazorpay();
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [schoolName, setSchoolName] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [pclass, setPclass] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [responseMessage, setResponseMessage] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(0);
  const [optionList, setOptionList] = useState([]);

  const selectStyle = {
    boxShadow: "none",
    border: "0",
    marginBottom: "0",
    padding: "10px 25px",
    maxWidth: "100%",
    width: "100%",
    fontSize: "14px",
    lineHeight: "30px",
    fontWeight: "600",
    transition: "all 0.3s linear",
  };

  function isMobileView() {
    return window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

  const clearResponse = () => {
    setTimeout(() => {
      setResponseStatus(null);
      setResponseMessage(null);
    }, 2000);
  };

  const activitiesFetch = () => {
    fetch(`${config.API_URL}ActivityDetail?slug=` + slug, {
      method: "GET",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => response.text())
      .then((data) => {
        var data = JSON.parse(data).data;
        setData(data);

        document.title = data[0].title;
        document.title = "Enroll in " + data[0].title;
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };

  const schoolsFetch = () => {
    fetch(`${config.API_URL}SchoolList`, {
      method: "GET",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => response.text())
      .then((data) => {
        var data = JSON.parse(data).data;
        // setSchools(data);
        const optionData = data.map((school) => ({
          value: parseInt(school.school_id), // Convert string to integer
          label: `${school.school_name}`,
        }));
        setOptionList(optionData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (typeof window.loadMainJS === "function") {
      window.loadMainJS();
    }
    window.scrollTo(0, 0);
    activitiesFetch();
    schoolsFetch();
  }, [slug]);

  const handlePayment = async (order_id) => {
	// console.log(`${schoolName} ${name} ${gender} ${pclass} ${age} ${email} ${contactNumber} ${whatsappNumber}`)
	// return
    // Show loading screen

    //  return false;

    const options = {
      key: "rzp_live_IsQtEDTsrfrHyi", // Enter the Key ID generated from the Dashboard
      currency: "INR",
      name: "Education Expo",
      description: `${schoolName} ${name} ${gender} ${pclass} ${age} ${email} ${contactNumber} ${whatsappNumber}`,
      image: "https://www.educationexpo.org/assets/logo/logo.png",
      order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        if (response.error && response.error.code === "payment.cancelled") {
          axios.get(`${config.API_URL}OrderDelete?order_id=${order_id}`);
          alert("Payment was cancelled.");
          setResponseStatus(false);
          setResponseMessage("Payment was cancelled.");
          clearResponse();
        } else {
          axios
            .get(`${config.API_URL}OrderConfirm?order_id=${order_id}`)
            .then((response) => {
              // Initiate the file download using XMLHttpRequest or fetch
              const xhr = new XMLHttpRequest();
              xhr.open(
                "GET",
                `${config.API_URL}generatePDF?order_id=${order_id}`,
                true
              );
              xhr.responseType = "blob"; // Set the response type to blob (binary data)
              xhr.onload = () => {
                const blob = xhr.response;
                const downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = `Acknowledgement${order_id}.pdf`;
                downloadLink.click();
                setResponseStatus(true);
                setResponseMessage("Registration Successful");
                clearResponse();
                setLoadingStatus(0);
              };
              xhr.send();
            })
            .catch((error) => {
              console.error("Error:", error);
              // Hide the loading screen in case of an error
              setLoadingStatus(0);
            });
        }
      },
      modal: {
        ondismiss: () => {
          axios.get(`${config.API_URL}OrderDelete?order_id=${order_id}`);
        },
      },
      notes: {
        address: "Education Expo",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);
    rzp1.open();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      school_name: schoolName,
      name: name,
      gender: gender,
      class: pclass,
      age: age,
      email: email,
      contact_number: contactNumber,
      whatsapp_number: whatsappNumber,
      activity_id: data[0].activity_id,
    };

    setLoadingStatus(1);
    fetch(`${config.API_URL}ActivitiesEnrollment`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: JSON.stringify(formData),
    })
      .then((response) => response.text())
      .then((data) => {
        setLoadingStatus(0);
        data = JSON.parse(data);
        if (data.success == true) {
          handlePayment(data.order_id);
        } else {
          setResponseStatus(false);
          setResponseMessage(data.message);
          clearResponse();
        }
      })
      .catch((error) => {
        setLoadingStatus(0);
        // Handle any errors
        console.error(error);
        setResponseStatus(false);
        setResponseMessage(error.message);
      });
  };

  return (
    <div>
      <HeroHeader
        title={
          data == null ? "Loading..." : "Enroll for " + data[0].short_title
        }
      />
      <div className="login-register-area section-padding-02">
        <div className="container">
          <div className="row justify-content-center g-5">
            <div className="col-lg-10 col-12">
              <div className="hero-form mt-0 grey-bg">
                <h3 className="form-title black">
                  Enrollment in {data == null ? "..." : data[0].title}
                </h3>
                <form
                  action="#"
                  className="exvent-form"
                  onSubmit={handleSubmit}
                >
                  <div className="row gy-4">
                    <div className="col-12">
                      <div className="form-group">
                        <div className="single-input white-bg">
                          <Select
                            options={optionList}
                            placeholder="Select School"
                            onChange={(data) => {
                              setSchoolName(data.label);
                            }}
                            isSearchable={true}
                            className="custom-select"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-input white-bg">
                        <input
                          type="text"
                          placeholder="Full Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                          <div className="single-input white-bg">
                            <select
                              className="form-control"
                              placeholder="Select Gender"
                              style={selectStyle}
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <option value={""} selected disabled>
                                Select Gender
                              </option>
                              <option value={"male"}>Male</option>
                              <option value={"female"}>Female</option>
                            </select>
                          </div>
                    </div>
                    <div className="col-lg-12">
                          <div className="single-input white-bg">
                            <select
                              className="form-control"
                              placeholder="Select Class"
                              style={selectStyle}
                              onChange={(e) => setPclass(e.target.value)}
                            >
                              <option value={""} selected disabled>
                                Select Class
                              </option>
                              <option value={"4"}>4th</option>
                              <option value={"5"}>5th</option>
                              <option value={"6"}>6th</option>
                              <option value={"7"}>7th</option>
                              <option value={"8"}>8th</option>
                              <option value={"9"}>9th</option>
                              <option value={"10"}>10th</option>
                              <option value={"12"}>11th</option>
                              <option value={"13"}>12th</option>
                            </select>
                          </div>
                    </div>
                    <div className="col-lg-12">
                          <div className="single-input white-bg">
                            <input
                              type="number"
                              placeholder="Age"
                              onChange={(e) => setAge(e.target.value)}
                            />
                          </div>
                    </div>
                    <div className="col-12">
                      <div className="single-input white-bg">
                        <input
                          type="number"
                          placeholder="Contact Number"
                          value={contactNumber}
                          onChange={(e) => setContactNumber(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-input white-bg">
                        <input
                          type="text"
                          placeholder="Whatsapp Number"
                          value={whatsappNumber}
                          onChange={(e) => setWhatsappNumber(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-input white-bg">
                        <input
                          type="email"
                          placeholder="E-Mail"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      {loadingStatus == 1 ? (
                        <LoadingOverlay
                          msg=" <p>Please wait while we process your request and generate your certificate.</p>
						<p>Do not close this tab.</p>
						<p>Your certificate will be sent to the email address you provided.</p>"
                        />
                      ) : (
                        ""
                      )}{" "}
                      {responseStatus !== null && (
                        <Alert
                          msg={responseMessage}
                          type={responseStatus == true ? "success" : "danger"}
                        />
                      )}
                    </div>
                    <div className="col-12" id="resp"></div>
                    <div className="col-sm-12 text-center">
                      <button
                        type="submit"
                        disabled={loadingStatus == 1 ? true : false}
                        className={
                          loadingStatus == 1 ? "disabled_btn" : "submit_btn"
                        }
                        style={{ width: "100%" }}
                      >
                        Enroll
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default ActivityEnrollment;
