import React, {useState,useEffect} from 'react';
import { useParams, NavLink } from "react-router-dom";
import {Card} from '../Components/Cards'
import * as config from '../config';
import {Loading,SmallActivityLabel,Alert,HeroHeader} from '../Components/Elements';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';

const StudentRegistration = () =>{
    const { slug } = useParams();
    const [data, setData] = useState(null);
    const [schoolName,setSchoolName] = useState('');
    const [activityId,setActivityId] = useState('');
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [contactNumber,setContactNumber] = useState('');
    const [whatsappNumber,setWhatsappNumber] = useState('');
    const [responseMessage, setResponseMessage] = useState(null);
    const [responseStatus, setResponseStatus] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(0);

    const activitiesFetch = ()=>{
      fetch(`${config.API_URL}ActivityDetail?slug=`+slug, {
        method: 'GET',
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
      })
      .then(response => response.text())
      .then(data => {
        var data = JSON.parse(data).data;
        setData(data);

        document.title = data[0].title;
        document.title = "Enroll in "+data[0].title;
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });
    };

    useEffect(() => {

        if (typeof window.loadMainJS === 'function') {
          window.loadMainJS();
        }
        window.scrollTo(0, 0);
        activitiesFetch();
      }, [slug]);
      const handleSubmit = (e)=>{
        e.preventDefault();
        const formData = {
          school_name:schoolName,
          activity_id:activityId,
          name:name,
          email:email,
          contact_number:contactNumber,
          whatsapp_number:whatsappNumber,
          activity_id:data[0].activity_id,
          // Other form field values
        };

        setLoadingStatus(1);
        fetch(`${config.API_URL}ActivitiesRegistration`, {
          method: 'POST',
          headers: {"Content-Type": "application/x-www-form-urlencoded"},
          body: JSON.stringify(formData),
        })
        .then(response => response.text()) // Convert the response to text
        .then(data => {
            setLoadingStatus(0);
            if(JSON.parse(data).success == true){
              // Reset form fields
                setSchoolName('');
                setActivityId('');
                setName('');
                setEmail('');
                setContactNumber('');
                setWhatsappNumber('');
            }
            setResponseStatus(JSON.parse(data).success);
            setResponseMessage(JSON.parse(data).message);

        })
        .catch(error => {
          setLoadingStatus(0);
          // Handle any errors
          console.error(error);
          setResponseStatus(false);
          setResponseMessage(error.message);
        });
      };
    return (
        <div>
            {/* <Navigation /> */}
            <HeroHeader title={ data == null?"Loading...":"Enroll for " + (data[0].short_title)} />
            <div className="login-register-area section-padding-02">
                <div className="container">
                    <div className="row justify-content-center g-5">
                        <div className="col-lg-6 col-md-8">
                        <div className="hero-form mt-0 grey-bg">
                            <h3 className="form-title black">Enrollment in {data == null?"...":data[0].title}</h3>
                            <form action="#" className="exvent-form"  onSubmit={handleSubmit}>
                            <div className="row gy-4">

                                <div className="col-12">
                                    <div className="single-input white-bg">
                                    <input type="text" placeholder="School Name" value={schoolName} onChange={(e) => setSchoolName(e.target.value)} />
                                        {/* <select className='form-control' placeholder="School Medium" value={schoolName} onChange={(e) => setSchoolName(e.target.value)} >
                                        <option value="" selected disabled>Select School</option>
                                        {schools && schools.map((item, index) => (
                                            (<option value={item.school_id} key={index}>{item.school_name}</option>)
                                        ))}

                                        </select> */}
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="single-input white-bg">
                                        <input type="text" placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="single-input white-bg">
                                        <input type="number" placeholder="Contact Number" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="single-input white-bg">
                                        <input type="text" placeholder="Whatsapp Number" value={whatsappNumber} onChange={(e) => setWhatsappNumber(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="single-input white-bg">
                                        <input type="email" placeholder="E-Mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>





                                <div className="col-12">
                                {loadingStatus == 1? (<Loading msg="Please wait..."/>):("") } {responseStatus !== null && (<Alert msg={responseMessage} type={responseStatus == true?"success":"danger"} />)}
                                </div>
                                <div className='col-12' id='resp'></div>
                                <div className="col-sm-5 text-center">
                                <button type="submit" disabled={loadingStatus==1?true:false} className="submit_btn" >Enroll</button>
                                </div>
                            </div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}
export default StudentRegistration;
